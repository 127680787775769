import React, { FC } from "react";
import { ContactInfo } from "./ContactInfo";
import { Social } from "./Social";
import { Links } from "./Links";
import "./navbar.style.scss";

export const NavbarContact: FC = () => {
  return (
    <>
      <div className="nav-contact">
        <ContactInfo />
      </div>
      <div className="nav-contact-icons">
        <Social />
      </div>
      <div className="nav-contact-links">
        <Links />
      </div>
    </>
  );
};
