import React from "react";
import CanvasTestStartOverlay from "../CanvasTestStartOverlay/CanvasTestStartOverlay";
import CanvasCompeteStartOverlay from "../CanvasCompeteStartOverlay/CanvasCompeteStartOverlay";
import { connect } from "react-redux";
import "./canvasstartoverlay.style.scss";
export const CANVAS_START_OVERLAY_CLASS = "canvas-start-overay";

type Props = {
  isCompeting: boolean;
  canStartDrawing: boolean;
  canJoinClub: boolean;
  fadeOut: boolean;
  canvasWidth: number;
  canvasHeight: number;
};

const CanvasStartOverlay = ({
  canvasWidth,
  canvasHeight,
  isCompeting,
  canStartDrawing,
  fadeOut,
}: Props) => {
  return (
    <div
      className={
        fadeOut
          ? `${CANVAS_START_OVERLAY_CLASS} ${CANVAS_START_OVERLAY_CLASS}--fadeout`
          : `${CANVAS_START_OVERLAY_CLASS} ${CANVAS_START_OVERLAY_CLASS}--fadein `
      }
      style={{
        width: `${canvasWidth}px`,
        height: `${canvasHeight}px`,
        marginBottom: `-${canvasHeight}px`,
      }}
    >
      {isCompeting ? (
        <CanvasCompeteStartOverlay canStartDrawing={canStartDrawing} />
      ) : (
        <CanvasTestStartOverlay />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { canvasWidth, canvasHeight } = state;
  return {
    canvasWidth,
    canvasHeight,
  };
};

export default connect(mapStateToProps, {})(CanvasStartOverlay);
