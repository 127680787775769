import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUsersScore, getScoredModels } from "../../state/actions";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import axios from "axios";
import config from "../../modules/config";
import { ProfileAvatar } from "../profile/ProfileAvatar";
import { Typography } from "@mui/material";
import { RootState } from "../../state/reducers";
import { ProfileAvatarAnonymous } from "../profile/ProfileAvatarAnonymous";
import { UsersScore } from "../../types/UsersScore";
import { User } from "../../types/user";
import "../profile/profile.style.scss";
import "./userProfileContent.style.scss";

interface Props {
  getUsersScore: (num: number) => void;
  usersScore: UsersScore;
}

type State = {
  userRank: string;
  userScore: string;
  userInfo: any;
};

const UserProfileContent = (props) => {
  const [userInfo, setUserInfo] = useState<any>({});
  const [userRank, setUserRank] = useState("");
  const [userScore, setUserScore] = useState("");
  props.getUsersScore(1);

  useEffect(() => {
    let athorizedHeader = config.AuthorizationHeader();
    axios
      .get(
        `${process.env.REACT_APP_CROQEE_SERVER_URL}/api/user/` + props.userId,
        athorizedHeader
      )
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch((err) => console.error(err));
  }, [props.userId]);

  useEffect(() => {
    props.usersScore.data &&
      props.usersScore.data.forEach((user: any) => {
        if (user._id === props.userId) {
          setUserRank(user.rank);
          setUserScore(user.total);
        }
      });
  }, [props.userId, props.usersScore]);

  return (
    <>
      {userInfo && (
        <div className="userProfile">
          <div className="profile__img-name-wrapper">
            <div className="profile__img-name-wrapper__img">
              {userInfo.img?.image_data ? (
                <ProfileAvatar
                  imageSrc={`${process.env.REACT_APP_CROQEE_SERVER_URL}/user-image/${userInfo?.img?.image_data}`}
                />
              ) : (
                <ProfileAvatarAnonymous />
              )}
            </div>
            <div className="profile__img-name-wrapper__name">
              <span>Name</span>
              <h1>{userInfo.name}</h1>
            </div>
          </div>
          <div className="profile__ranking">
            <h2>
              Rank #
              <span className="profile__ranking__highlight">{userRank}</span>
            </h2>
            <h2>
              Score{" "}
              <span className="profile__ranking__highlight">{userScore}</span>
            </h2>
          </div>
          <div className="profile__userInfo">
            <span>Email</span>
            <div className="profile__userInfo__container">
              <Typography variant="body2" style={{ fontSize: "1rem" }}>
                {userInfo.email}
              </Typography>
            </div>
          </div>
          <div className="profile__userInfo">
            <span>Location</span>
            <div className="profile__userInfo__container">
              <Typography variant="body2" style={{ fontSize: "1rem" }}>
                {userInfo.city ? userInfo.city : "Unspecified"}
              </Typography>
            </div>
          </div>
          <div className="profile__userInfo">
            <span>Birthdate</span>
            <div className="profile__userInfo__container">
              <Typography variant="body2" style={{ fontSize: "1rem" }}>
                {userInfo.birthDate
                  ? userInfo.birthDate.substring(0, 10)
                  : "Unspecified."}
              </Typography>
            </div>
          </div>
          <div className="profile__links">
            <p>Links</p>
          </div>
          {userInfo.behance !== undefined && (
            <a href={userInfo.behance} className="profile__links__icons">
              <i className={"fab fa-2x fa-behance "} />
            </a>
          )}
          {userInfo.facebook !== undefined && (
            <a href={userInfo.facebook} className="profile__links__icons">
              <i
                className={"fab fa-2x fa-facebook"}
                // style={{ color: "#b8b8b8", margin: "1rem" }}
              />
            </a>
          )}
          {userInfo.instagram !== undefined && (
            <a href={userInfo.instagram} className="profile__links__icons">
              <i
                className={"fab fa-2x fa-instagram"}
                //style={{ color: "#b8b8b8", margin: "1rem" }}
              />
            </a>
          )}
          {userInfo.website !== undefined && (
            <a href={userInfo.website} className="profile__links__icons">
              <i
                className={"far fa-2x fa-user-circle"}
                // style={{ color: "#b8b8b8", margin: "1rem" }}
              />
            </a>
          )}
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state: RootState) => {
  const { usersScore, user } = state;
  return { usersScore, user };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getUsersScore: () => dispatch(getUsersScore()),
    getScoredModels: () => dispatch(getScoredModels()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfileContent);
