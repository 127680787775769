import React, { FC } from "react";
type Props = {
};

export const ProfileAvatarAnonymous: FC<Props> = () => {
  return (
    <div>
      <img style={{ width: 128, height: 128 }}
        src={require('../../images/user-profile.svg').default} alt="profile avatar"/>
    </div>
  );
};
