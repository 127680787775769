export function calcCanvasAndModelDim(cb?:any) {
  const screenWidth =
    document.documentElement.clientWidth ||
    document.body.clientWidth ||
    window.innerWidth;
    const screenHeight = 
    document.documentElement.clientHeight ||
    document.body.clientHeight ||
    window.innerHeight;
  let canvasWidth;
  let canvasHeight;
  let innerModelWidth;
  let innerModelHeight;
  let isWrapped;
  if (screenWidth < screenHeight) {
    canvasWidth = screenWidth;
    canvasHeight = screenHeight / 2;
    isWrapped = true;
  } else {
    canvasWidth = Math.floor(screenWidth / 2);
    canvasHeight = screenHeight;
    cb && cb();
  }
  if (canvasWidth / canvasHeight <= 1) {
    innerModelHeight = canvasWidth;
    innerModelWidth = canvasWidth;
  } else {
    innerModelHeight = canvasHeight;
    innerModelWidth = canvasHeight;
  }

  return { canvasWidth, canvasHeight, innerModelWidth, innerModelHeight, isWrapped };
}