import React, { useEffect, useState } from "react";
import Auth from "../../modules/Auth";
import { Link } from "react-router-dom";
import config from "../../modules/config";
import { connect } from "react-redux";
import { setUser, getUser } from "../../state/actions";
import { GoogleLogin } from "@react-oauth/google";
import { Card, Button, Typography } from "@mui/material";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import { InputChangeEvent } from "../../common/model/InputChangeEvent";
import { InputFormEvent } from "../../common/model/InputFormEvent";
import { AuthProps } from "../../types/AuthProps";
import { APP_URL } from "../../components/navbar/NavBar";
import { UserAuthRequirements } from "../../types/user";
import "./login.style.scss";
import Layout from "../../components/layout/layout";
import { LoginForm } from "../../components/loginform/LoginForm";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginPage = ({ isAuthenticated, getUser, ...props }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(props.user);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState<any>(null);

  useEffect(() => {
    const storedMessage = localStorage.getItem("successMessage");
    if (storedMessage) {
      localStorage.removeItem("successMessage");
      setSuccessMessage(storedMessage);
    }
    if (isAuthenticated) {
      navigate("/competes");
    }
  }, [navigate, isAuthenticated]);

  /**
   * Process the form.
   *
   * @param {object} event - the JavaScript event object
   */
  async function processForm(event: InputFormEvent) {
    // prevent default action. in this case, action is the form submission event
    event.preventDefault();
    // let UnAthorizedHeader = config.UnAthorizedHeader();

    // let UnAthorizedHeader = config.UnAthorizedHeader();
    // let formData = Object.assign({}, body, UnAthorizedHeader);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CROQEE_SERVER_URL}/auth/login`,
        user
      );
      if (response?.data) {
        const { token, user } = response.data;
        props.setUser(user);
        setErrors({});
        Auth.authenticateUser(token);
        getUser();

        setTimeout(() => {
          navigate(APP_URL);
        }, 100);
      }
    } catch (error) {
      const errorObj = error?.response?.data;
      if (errorObj) {
        const { errors, message } = errorObj;
        const errorLogs = errors ? errors : {};
        errorLogs.summary = message;
        setErrors(errorLogs);
      }
    }
  }

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  function changeUser(event: InputChangeEvent) {
    const field = event.target.name;
    let userTemp = user || {};
    userTemp[field] = event.target.value;
    setUser(userTemp);
  }

  const responseGoogle = (response: any) => {
    axios
      .post(`${process.env.REACT_APP_CROQEE_SERVER_URL}/auth/googleauth`, {
        googleCredentialToken: response.credential,
      })
      .then((res) => {
        const { token, user } = res.data;
        if (token) {
          props.setUser(user);
          setErrors({});
          Auth.authenticateUser(token);
          getUser();
          navigate(APP_URL);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <Layout>
      <div className="login">
        <Card variant="outlined" className={"login__card"} square>
          <LoginForm
            onSubmit={processForm}
            onChange={changeUser}
            errors={errors}
            successMessage={successMessage}
            user={user}
          />
          <GoogleLogin
            // render={renderProps => (
            //   <Button
            //     variant="contained"
            //     onClick={renderProps.onClick}
            //     disabled={renderProps.disabled}
            //     startIcon={<i className="fab fa-google"></i>}
            //   >
            //     Continue With Google
            //   </Button>
            // )}
            // width={'1558px'}
            onSuccess={responseGoogle}
            onError={() => responseGoogle}
            text={"continue_with"}
            size={"large"}
          />
        </Card>
        <div
          style={{
            textAlign: "left",
            margin: "auto",
            width: "300px",
            marginTop: "1em",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            style={{ fontSize: "1rem" }}
          >
            Don't have an account? <Link to={"/signup"}>Create one</Link>
          </Typography>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ fontSize: "1rem" }}
          >
            Forgot password? <Link to={"/forgotpassword/link"}>Send link</Link>
          </Typography>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state;
  return {
    isAuthenticated,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getUser: () => dispatch(getUser()),
    setUser: (user: any) => dispatch(setUser(user)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
