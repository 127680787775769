import React, { useEffect, useState } from "react";
import Auth from "../../modules/Auth";
import { Link, useNavigate } from "react-router-dom";
import config from "../../modules/config";
import { Typography, Box, Card } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { setUser, getUser } from "../../state/actions";
import { connect } from "react-redux";
import { RootState } from "../../state/reducers/index.js";
import { InputFormEvent } from "../../common/model/InputFormEvent.js";
import { InputChangeEvent } from "../../common/model/InputChangeEvent.js";
import { AuthProps } from "../../types/AuthProps";
import { APP_URL } from "../../components/navbar/NavBar";
import { User } from "../../types/user";
import Layout from "../../components/layout/layout";
import "./signup.style.scss";
import { SignUpForm } from "../../components/signupform/SignUpForm";
import { AuthError } from "../../types/AuthError";
import axios from "axios";

const SignUpPage = (props: AuthProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (Auth.isUserAuthenticated()) {
      navigate("/competes");
    }
  }, []);
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({
    email: "",
    name: "",
    password: "",
  });

  /**
   * Process the form.
   *
   * @param {object} event - the JavaScript event object
   */
  const processForm = (event: InputFormEvent) => {
    event.preventDefault();
    let UnAthorizedHeader = config.UnAthorizedHeader();
    axios
      .post(
        `${process.env.REACT_APP_CROQEE_SERVER_URL}/auth/signup`,
        user,
        UnAthorizedHeader
      )
      .then((response) => {
        setErrors({});
        localStorage.setItem("successMessage", response.data.message);
        return (window.location.href = "/login");
      })
      .catch((error) => {
        if (error?.response?.data) {
          const { errors } = error.response.data;
          setErrors(errors);
        }
      });
  };

  /**
   * Change the user object.
   *
   * @param {object} event - the JavaScript event object
   */
  const changeUser = (event: InputChangeEvent) => {
    const field = event.target.name;
    const _user = { ...user };
    _user[field] = event.target.value;
    setUser(_user);
  };

  const responseGoogle = (response: any) => {
    axios
      .post(`${process.env.REACT_APP_CROQEE_SERVER_URL}/auth/googleauth`, {
        googleCredentialToken: response.credential,
      })
      .then((res) => {
        const { token, user } = res.data;
        props.setUser(user);
        setErrors({});
        Auth.authenticateUser(token);
        props.getUser();
        navigate(APP_URL);
      })
      .catch((err) => console.error(err));
  };

  /**
   * Render the component.
   */
  return (
    <Layout>
      <Box className={"signup"}>
        <Card className="signup__card">
          <SignUpForm
            onSubmit={processForm}
            onChange={changeUser}
            errors={errors}
            user={user as any}
          />
          <GoogleLogin
            // render={renderProps => (
            //   <Button
            //     variant="contained"
            //     onClick={renderProps.onClick}
            //     disabled={renderProps.disabled}
            //     startIcon={<i className="fab fa-google"></i>}
            //   >
            //     Continue With Google
            //   </Button>
            // )}
            onSuccess={responseGoogle}
            onError={() => responseGoogle}
            text={"continue_with"}
            size={"large"}
          />
        </Card>
        <div
          style={{
            textAlign: "center",
            margin: "auto",
            width: "100%",
            marginTop: "1em",
          }}
        >
          <Typography style={{ fontSize: "1rem" }}>
            Already a member? <Link to={"/login"}>Login</Link>.
          </Typography>
        </div>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => {};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getUser: () => dispatch(getUser()),
    setUser: (user: any) => dispatch(setUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
