import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getUser,
  setTimer,
  invokeScore,
  setImageProcessing,
  setTimerDone,
  setActiveModel,
} from "../../state/actions";
import Timer from "../../components/timer/Timer";
import { UserPendingLoader } from "../../components/userpendingloader/UserPendingLoader";
import socketIOClient from "socket.io-client";
import Auth from "../../modules/Auth";
import Canvas, { CANVAS_ID } from "../../components/canvas/Canvas/Canvas";
// import DrawingModel from "../../components/model/DrawingModel/DrawingModel"
import { UserProfileData } from "../../types/UserProfileData";
import { RootState } from "../../state/reducers";
import { DrawingEnvironment } from "../../components/drawingenvironment/DrawingEnvironment";
import InteractiveDrawingModel from "../../components/model/InteractiveDrawingModel/InteractiveDrawingModel";
import DrawingModel, {
  Model,
} from "../../components/model/DrawingModel/DrawingModel";
import "./compete.style.scss";
import { HomePageProps } from "../home";
import { useLocation } from "react-router-dom";
import { drawerClasses } from "@mui/material";

export const BREAK_TIME_AFTER_EACH_ITERATION = 10;

export type DataUrls = {
  drawingUrl: string;
  modelUrl?: string;
};

const CompetePage = ({
  user,
  getUser,
  setActiveModel,
  setTimer,
  setImageProcessing,
  invokeScore,
  setTimerDone,
  leftHand,
  showTimer,
}) => {
  const socket = useRef(null);
  let interactiveModel = useRef(null);
  const hasUserDrawnOnCanvasRef = useRef(false);
  const startDrawingRef = useRef(false);
  const modelRef = useRef(null);
  const [baseURL, setBaseURL] = useState<any>("");
  const [resetCanvas, setResetCanvas] = useState(false);
  const [canJoinClub, setCanJoinClub] = useState(false);
  const [playingUsers, setPlayingUsers] = useState<any>([]);
  const [showUserScores, setShowUserScores] = useState(false);
  const [drawingField, setDrawingField] = useState(null);
  const location = useLocation();
 
  useEffect(() => {
    setDrawingField(location.pathname.split("/")[2]);
  }, [location.pathname]);

  useEffect(() => {
    if (
      drawingField
    ) {
      let hasJoined = false;
      let isFirstTimePlaying = true;
      const getOutlineScreenShot = () => {
        const outlineBase64 = interactiveModel.current.getOutlineScreenShot();
        return outlineBase64;
      };
      const getModelScreenShot = () => {
        const outlineBase64 = interactiveModel.current.getModelScreenShot();
        return outlineBase64;
      };
      socket.current = socketIOClient(
        `${process.env.REACT_APP_CROQEE_SERVER_URL}`,
        {
          path: `/compete/${drawingField}/socket.io`,
        }
      );

      if (!user || Object.keys(user).length === 0) {
        getUser();
      }

      const token = Auth.getToken();
      socket.current.emit("username", token);
      socket.current.on("update_user", (users) => {
        setPlayingUsers(users);
      });
      socket.current.on("join_club", (model) => {
        if (!hasJoined) {
          setCanJoinClub(true);
          hasJoined = true;
          modelRef.current = model;
        }
      });

      socket.current.on("start_drawing", (model) => {
        setTimeout(() => {
          setActiveModel({
            modelDataURL: "",
          });
        }, 5000);
        if (hasJoined) {
          modelRef.current = model;
          startDrawingRef.current = true;
          setShowUserScores(false);
          setTimer({ showTimer: true, timer: model.givenTime });
        }
      });

      socket.current.on("send_your_drawing", () => {
        if (startDrawingRef.current) {
          const canvas = document.getElementById(
            CANVAS_ID
          ) as HTMLCanvasElement;
          if (canvas) {
            setImageProcessing(true);
            setTimer({ showTimer: false, timer: 0 });
            let dataURL = "";
            if (hasUserDrawnOnCanvasRef.current) {
              dataURL = canvas
                .toDataURL("image/jpeg", 0.8)
                .replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
            }
            const dataUrls: DataUrls = {
              drawingUrl: dataURL,
            };
            if (modelRef.current.threeDModelUrl) {
              const modelOutlineDataURL = getOutlineScreenShot();
              dataUrls.modelUrl = modelOutlineDataURL.replace(
                /^data:image\/(png|jpg|jpeg);base64,/,
                ""
              );
            }
            socket.current.emit("my_drawing", dataUrls);
            if (modelRef.current.threeDModelUrl) {
              const modelDataURL = getModelScreenShot();
              setActiveModel({
                modelDataURL: modelDataURL,
              });
            }
          }
        }
      });

      socket.current.on("evaluated_score", (scoreDetails) => {
        if (startDrawingRef.current) {
          setImageProcessing(false);
          setBaseURL(
            scoreDetails.img != null
              ? "data:image/png;base64, " + scoreDetails.img
              : ""
          );
          setResetCanvas(true);

          setTimeout(() => {
            invokeScore(scoreDetails.score);
            hasUserDrawnOnCanvasRef.current = false;
          }, 10);
        }
      });

      socket.current.on("users_score", (stillLifePlayers) => {
        if (!isFirstTimePlaying) {
          setShowUserScores(true);
          setPlayingUsers(stillLifePlayers);
        } else {
          isFirstTimePlaying = false;
        }
      });
      return () => {
        socket.current.close();
        setTimer({ showTimer: false, timer: 0 });
        setTimerDone(true);
      };
    }
  }, [
    drawingField,
    user,
    getUser,
    setActiveModel,
    setTimer,
    setImageProcessing,
    invokeScore,
    setTimerDone,
    setCanJoinClub,
    interactiveModel,
  ]);

  const setShouldResetCanvas = (bool: boolean) => {
    setResetCanvas(bool);
  };

  const side = leftHand ? "model_left_hand" : "";

  return (
    <div className="compete-page">
      {!canJoinClub ? (
        <div className="compete-page__wait-for-game">
          <UserPendingLoader
            caption={"Please wait until the next round begins..."}
          />
        </div>
      ) : (
        <div>
          <DrawingEnvironment side={side}>
            {showTimer && startDrawingRef.current && <Timer />}
            {modelRef.current.threeDModelUrl ? (
              <InteractiveDrawingModel
                compete={true}
                componentRef={interactiveModel}
                modelUrl={modelRef.current.threeDModelUrl}
                allowUserCameraControl={false}
                showUserScores={showUserScores}
                playingUsers={playingUsers}
                cameraPosition={modelRef.current.cameraPosition}
                modelScale={modelRef.current.modelScale}
                controlTarget={modelRef.current.controlTarget}
                normalMultiplier={modelRef.current.normalMultiplier}
                outLineSpec={modelRef.current.outLineSpec}
              />
            ) : (
              <DrawingModel
                model={modelRef.current}
                compete={true}
                user={user as any}
                playingUsers={playingUsers}
                showUserScores={showUserScores}
                imgPath={drawingField}
              />
            )}
            <Canvas
              shouldResetCanvas={resetCanvas}
              setShouldResetCanvas={setShouldResetCanvas}
              setHasUserDrawnOnCanvas={(bool) => {
                hasUserDrawnOnCanvasRef.current = bool;
              }}
              canStartDrawing={startDrawingRef.current}
              canJoinClub={canJoinClub}
              isCompeting={true}
              baseURL={baseURL}
              model={modelRef.current}
              imgPath={drawingField}
              isInHomePage={false}
              navigateToClubPage={function (): void {
                throw new Error("Function not implemented.");
              }}
              setBaseUrl={function (baseURL: string): void {
                throw new Error("Function not implemented.");
              }}
            />
          </DrawingEnvironment>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: RootState) => {
  const {
    user,
    showTimer,
    leftHand,
  } = state;
  return {
    user,
    showTimer,
    leftHand,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUser()),
    setTimer: (payload) => dispatch(setTimer(payload)),
    invokeScore: (payload) => dispatch(invokeScore(payload)),
    setImageProcessing: (payload) => dispatch(setImageProcessing(payload)),
    setTimerDone: (payload) => dispatch(setTimerDone(payload)),
    setActiveModel: (payload) => dispatch(setActiveModel(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompetePage);
