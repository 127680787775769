import React, { FC } from "react";
import { InputChangeEvent } from "../../common/model/InputChangeEvent";
import { InputFormEvent } from "../../common/model/InputFormEvent";
import { User } from '../../types/user';
import { Box, Button, TextField } from "@mui/material";
import "./signupForm.style.scss";
import { AuthError } from "../../types/AuthError";
type Props = {
  onSubmit: (e: InputFormEvent) => void;
  onChange: (e: InputChangeEvent) => void;
  errors: AuthError;
  user: User;
};

export const SignUpForm: FC<Props> = ({ onSubmit, onChange, errors, user }) => (
  <Box>
    <div className="container">
      <form action="/" onSubmit={onSubmit} className="auth_form" noValidate>
        <h2 className="card-heading">Sign Up</h2>

        {errors?.summary && <p className="error-message">{errors.summary}</p>}

        <div className="field-line">
          <TextField
            required
            label="Username"
            id="name"
            name="name"
            onChange={onChange}
            value={user.name}
            placeholder="Your Username"
            error={Boolean(errors?.name || errors?.summary)}
            helperText={errors?.name ? errors?.name : errors?.summary}
            className={"auth_form-input"}
          />
        </div>

        <TextField
          required
          label="Email"
          id="email"
          name="email"
          onChange={onChange}
          value={user.email}
          placeholder="Your Email address"
          error={Boolean(errors?.email || errors?.summary)}
          helperText={errors?.email || errors?.summary}
          className={"auth_form-input"}
        />

        <TextField
          required
          label="Password"
          id="password"
          type="password"
          name="password"
          onChange={onChange}
          value={user.password}
          placeholder="Your Password "
          error={Boolean(errors?.password || errors?.summary)}
          helperText={errors?.password ? errors?.password : errors?.summary}
          className={"auth_form-input"}
        />

        <Button className={"auth_form-input"}
          variant="outlined" type="submit">
          Create an account
        </Button>
      </form>
    </div>
  </Box>
);
