export const GET_USER = "GET_USER";
export const GET_USER_ASYNC = "GET_USER_ASYNC";
export const SET_USER = "SET_USER";
export const AUTHENTICATE = "AUTHENTICATE";
export const SET_TIMER = "SET_TIMER";
export const INVOKE_SCORE = "INVOKE_SCORE";
export const SHOW_SCORE = "SHOW_SCORE";
export const HIDE_SCORE = "HIDE_SCORE";
export const REMOVE_SCORE = "REMOVE_SCORE";
export const SET_IMAGE_PROCESSING = "SET_IMAGE_PROCESSING";
export const SET_HAND_SIDE = "SET_HAND_SIDE";
export const SET_TIMER_DONE = "SET_TIMER_DONE";
export const SET_START_IMAGE_PROCESSING = "SET_START_IMAGE_PROCCESSING";
export const SET_PAGE_TO_NAVIGATE_AFTER_LOGIN = "SET_PAGE_TO_NAVIGATE_AFTER_LOGIN";
export const SET_ACTIVE_MODEL = "SET_ACTIVE_MODEL";
export const SET_ACTIVE_MODEL_DRAWN = "SET_ACTIVE_MODEL_DRAWN";
export const GET_USERS_SCORE_ASYNC = "GET_USERS_SCORE_ASYNC";
export const GET_SCORED_MODELS_ASYNC = "GET_SCORED_MODELS_ASYNC";
export const SET_CANVAS_DIMENSION = "SET_CANVAS_DIMENSION";
export const SET_INNER_MODEL_DIMENSION = "SET_INNER_MODEL_DIMENSION";
export const SET_IS_DRAWING_STARTED = "SET_IS_DRAWING_STARTED";
export const SET_BRUSH_SIZE = "SET_BRUSH_SIZE";
export const SET_DRAWING_EVALUATION_ERROR = "SET_DRAWING_EVALUATION_ERROR";
export const GET_ALL_MODELS_ASYNC = "GET_ALL_MODELS_ASYNC";