import React from "react";
import { NavLink, useMatch } from "react-router-dom";
import "./accountsidenav.style.scss";

// Constants for routes
const ROUTES = {
  PROFILE: "/account/profile",
  PASSWORD: "/account/password",
  PRIVACY: "/account/privacy",
};

const AccountSideNav = () => {
  const profileMatch = useMatch(ROUTES.PROFILE);
  const passwordMatch = useMatch(ROUTES.PASSWORD);
  const privacyMatch = useMatch(ROUTES.PRIVACY);

  return (
    <div className="account-sideNav">
      <NavLink
        to={ROUTES.PROFILE}
        className={
          profileMatch ? "account-sideNav__active" : "account-sideNav__inActive"
        }
      >
        Profile
      </NavLink>
      <NavLink
        to={ROUTES.PASSWORD}
        className={
          passwordMatch ? "account-sideNav__active" : "account-sideNav__inActive"
        }
      >
        Password and Security
      </NavLink>
      <NavLink
        to={ROUTES.PRIVACY}
        className={
          privacyMatch ? "account-sideNav__active" : "account-sideNav__inActive"
        }
      >
        Privacy
      </NavLink>
    </div>
  );
};

export default AccountSideNav;
