import React from "react"
import Layout from "../../components/layout/layout";
import UserProfileContent from "../../components/userprofile/UserProfileContent";
type UserProfileProps = {
  userId?: string;
  path?: any;
}
const UserProfile = (props: UserProfileProps) => {
  const { userId } = props;
  return (
    <div>
      {<Layout>
        {userId && <UserProfileContent userId={userId} />}
      </Layout>}
    </div>
  )
}

export default UserProfile;