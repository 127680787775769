import React, { Component, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  setTimer,
  setTimerDone,
  setStartImageProcessing,
} from "../../state/actions";
import "./timer.style.scss";

type Props = {
  setStartImageProcessing: (bool: boolean) => void;
  timer: number;
};
const Timer = ({ setStartImageProcessing, timer }: Props) => {
  const loader = useRef(null);


  
 
  useEffect(() => {
    let processIntrupted = false;
    let alpha = 360;
    let pi = Math.PI;
    let t = timer * 2.7;
    let start = new Date().getTime();
    let done = false;

    const draw = () => {
      alpha-- ;
      if (alpha === 0 && !processIntrupted) {
        done = true;
        var end = (new Date() as any) - start;
        console.info("Execution time: %dms", end);
        setStartImageProcessing(true);
      }
      var r = (alpha * pi) / 180,
        x = Math.sin(r) * 125,
        y = Math.cos(r) * -125,
        mid = alpha > 180 ? 1 : 0,
        anim = "M 0 0 v -125 A 125 125 1 " + mid + " 1 " + x + " " + y + " z";
  
      loader?.current?.setAttribute("d", anim);
      if (!done && !processIntrupted) {
        setTimeout(draw, t); // Redraw
      }
    };
    draw();
    return () => {
      processIntrupted = true;
    };

  }, [setStartImageProcessing, timer]);



  return (
    <React.Fragment>
      <svg className={`timer`} width="100" height="100">
        <path
          id="loader"
          className={"path"}
          ref={loader}
          transform="translate(50, 50) scale(.200)"
        />
      </svg>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { timer, timerDone } = state;
  return { timer, timerDone };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTimer: (payload) => dispatch(setTimer(payload)),
    setTimerDone: (payload) => dispatch(setTimerDone(payload)),
    setStartImageProcessing: (payload) =>
      dispatch(setStartImageProcessing(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Timer);
