import React, { Fragment, FC } from "react";
import { Link } from "react-router-dom";

export const Links: FC = () => {
  return (
    <Fragment>
      <Link to="#">Contact</Link>
      <Link to="/account/privacy/">Privacy policy</Link>
      <Link to="#">Terms & Conditions</Link>
    </Fragment>
  );
};
