import React, { Component } from "react";
import "./loader.style.scss";

interface ILoaderProps {
  canvasBlocker?: boolean
}
interface ILoaderStates {
  fadeIn: string
}
export class Loader extends Component<ILoaderProps, ILoaderStates> {
  state = {
    fadeIn: ""
  };

  componentDidMount() {
    this.setState({
      fadeIn: "loader-canvas-blocker--fade-in"
    });
  }
  render() {
    const { fadeIn } = this.state;
    const { canvasBlocker } = this.props;
    return (
      <>
        {canvasBlocker && <div className={"loader-canvas-blocker " + fadeIn}></div>}
        <span className="loader">
          <span className="loader__inner"></span>
        </span>
      </>
    );
  }
}
