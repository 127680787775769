import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ClubThumbnail } from "../../components/clubs/ClubThumbnail";
import Layout from "../../components/layout/layout";
import { getAllModels } from "../../state/actions";
import { RootState } from "../../state/reducers";
import { useNavigate } from "react-router-dom"; 
import "./competes.style.scss";

const COMPETES_CLASSNAME = "competes";

const Competes = ({allModels, getAllModels, isAuthenticated}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else{
      getAllModels();
    }
  }, [navigate, isAuthenticated, getAllModels]);

  const handleNavigationToCompetition = (drawingField: string): void => {
    navigate(`/compete/${drawingField}`);
  };

  return (
    <Layout>
      <div className={COMPETES_CLASSNAME}>
        <div className={`${COMPETES_CLASSNAME}__wrapper`}>
          <h1 className={`${COMPETES_CLASSNAME}__wrapper-header`}>
            Select the subject to enter the competition
          </h1>
          {allModels.map((model: any, index: any) => (
            <ClubThumbnail
              drawingField={model.name}
              text={model.title}
              handleNavigationToCompetition={handleNavigationToCompetition}
              image={model.thumbnail}
              key={index}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => {
  const { allModels, isAuthenticated } = state;
  return {
    allModels,
    isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllModels: () => dispatch(getAllModels()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Competes);
