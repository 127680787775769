import React, { Component, Fragment, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import axios from "axios";
import config from "../../modules/config";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Auth from "../../modules/Auth";
import { connect } from "react-redux";
import { authenticate, setUser } from "../../state/actions";
import { DeleteAccount } from "../account/DeleteAccount";
import { InputChangeEvent } from "../../common/model/InputChangeEvent";
import { InputFormEvent } from "../../common/model/InputFormEvent";
import { Callback } from "../../common/model/Callbacks";
import { PasswordInputsTypes } from "../../types/PasswordInputsTypes";
import "./password.style.scss";
import { useNavigate } from "react-router";
const SUCCESS_MESSAGE = "Updated";

const Password = () => {
  const navigate = useNavigate();
  const [radioValue, setRadioValue] = useState(null);
  const [helperText, setHelperText] = useState("");
  const [isPasswordNull, setIsPasswordNull] = useState(true);
  const [msg, setMsg] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [password, setPassword] = useState<any>({
    currentPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  });
  const [inputErrors, setInputErrors] = useState<any>({});
  const [err, setErr] = useState("");
  //Check if the password is null or not (if it's null it means that user does not have any local account registered)
  useEffect(() => {
    const athorizedHeader = config.AuthorizationHeader();

    axios
      .get(
        `${process.env.REACT_APP_CROQEE_SERVER_URL}/api/password`,
        athorizedHeader
      )
      .then((res) => {
        setIsPasswordNull(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onChange: any = (e) => {
    let input = e.target;
    const isValid = input.checkValidity();

    // Update password state
    setPassword((prevPassword) => ({
      ...prevPassword,
      [input.name]: input.value,
    }));

    // Reset message and error
    setMsg("");
    setErr("");

    // Update inputErrors state
    if (!isValid) {
      setInputErrors((prevInputErrors) => ({
        ...prevInputErrors,
        [input.name]: input.validationMessage,
      }));
    } else {
      setInputErrors((prevInputErrors) => ({
        ...prevInputErrors,
        [input.name]: "",
      }));
    }
  };

  const handleRadioChange = () => {
    setRadioValue("yes");
  };

  const deleteHandleSubmit = (e) => {
    e.preventDefault();
    const athorizedHeader = config.AuthorizationHeader();

    axios
      .delete(
        `${process.env.REACT_APP_CROQEE_SERVER_URL}/api/account`,
        athorizedHeader
      )
      .then((res) => {
        Auth.deauthenticateUser();
        authenticate(false);
        setUser({});
        navigate("/"); // Using useHistory hook for redirection
      })
      .catch((err) => {
        setDeleteError(err);
      });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = form.checkValidity();
    const formData = new FormData(form) as any;
    const validationMessages = Array.from(formData.keys()).reduce(
      (acc: any, key: any) => {
        acc[key] = form.elements[key].validationMessage;
        return acc;
      },
      {}
    );

    setInputErrors(validationMessages);

    if (isValid) {
      if (password.newPassword !== password.repeatNewPassword) {
        setInputErrors({
          newPassword: "Passwords do not match.",
          repeatNewPassword: "Passwords do not match.",
        });
      } else if (password.newPassword.length < 8) {
        setInputErrors({
          newPassword: "Password must be at least 8 characters.",
          repeatNewPassword: "Password must be at least 8 characters.",
        });
      } else {
        let athorizedHeader = config.AuthorizationHeader();
        axios
          .post(
            `${process.env.REACT_APP_CROQEE_SERVER_URL}/api/password`,
            {
              currentPassword: password.currentPassword,
              newPassword: password.newPassword,
            },
            athorizedHeader
          )
          .then((res) => {
            if (res.status === 200) {
              setMsg(SUCCESS_MESSAGE);
              setPassword({
                currentPassword: "",
                newPassword: "",
                repeatNewPassword: "",
              });
            }
          })
          .catch((err) => {
            setPasswordError(err.response.data.error);
          });
      }
    }
  };

  return (
    <Fragment>
      <div className="security">
        <h2>Password and Security</h2>
        <Divider />

        <h3>Change Password</h3>
        {msg !== "" && (
          <Chip
            size="medium"
            label={msg}
            disabled
            icon={<DoneIcon />}
            style={{ color: "#00FF00", width: "260px", margin: "1rem 0" }}
            variant="outlined"
          />
        )}
        {passwordError !== "" && (
          <Chip
            size="medium"
            label={passwordError}
            disabled
            icon={<CloseIcon />}
            style={{ color: "#FF0000", width: "260px", margin: "1rem 0" }}
            variant="outlined"
          />
        )}
        {isPasswordNull && (
          <FormHelperText>
            It seems like you don not have any local account registered. To
            change your password please create an account locally.
          </FormHelperText>
        )}
        {msg !== SUCCESS_MESSAGE && (
          <form
            noValidate
            className="security__change_password"
            onSubmit={handleChangePassword}
          >
            <TextField
              required
              type="password"
              className="security__change_password__field"
              onChange={onChange}
              name="currentPassword"
              id="filled-error"
              placeholder="Your Current Password"
              disabled={isPasswordNull}
              error={Boolean(Object.keys(inputErrors).length !== 0)}
              helperText={
                inputErrors.currentPassword && inputErrors.currentPassword
              }
            />
            <TextField
              required
              type="password"
              className="security__change_password__field"
              onChange={onChange}
              name="newPassword"
              id="filled-error"
              placeholder="Your New Password"
              disabled={isPasswordNull}
              error={Boolean(Object.keys(inputErrors).length !== 0)}
              helperText={inputErrors.newPassword && inputErrors.newPassword}
            />
            <TextField
              required
              type="password"
              className="security__change_password__field"
              onChange={onChange}
              name="repeatNewPassword"
              id="filled-error"
              placeholder="Confrim Your New Password"
              disabled={isPasswordNull}
              error={Boolean(Object.keys(inputErrors).length !== 0)}
              helperText={
                inputErrors.repeatNewPassword && inputErrors.repeatNewPassword
              }
            />
            <Button
              type="submit"
              variant="outlined"
              disabled={isPasswordNull || !inputErrors}
            >
              Update Password
            </Button>
          </form>
        )}
        <Divider light />
        <DeleteAccount
          deleteError={deleteError}
          handleSubmit={deleteHandleSubmit}
          error={inputErrors}
          handleRadioChange={handleRadioChange}
          radioValue={radioValue}
        />
      </div>
    </Fragment>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    authenticate: (payload: any) => dispatch(authenticate(payload)),
    setUser: (payload: any) => dispatch(setUser(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Password);
