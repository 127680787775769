import React, { useEffect, useState } from "react";
import { User } from "../../../types/user";
import "./usersscores.style.scss";
const USERS_SCORES_CLASS = "users-scores";

type Props = {
  width: number;
  height: number;
  playingUsers: User[];
  user: User;
};

const UserScoreOverview = ({ width, height, playingUsers, user }: Props) => {
  const [usersScoreFadeClass, setUsersScoreFadeClass] = useState("");
  useEffect(() => {
    displayConcurrentUsersScores();
  }, []);

  const displayConcurrentUsersScores = () => {
    setUsersScoreFadeClass(`${USERS_SCORES_CLASS}--fadein`);
    setTimeout(() => {
      setUsersScoreFadeClass(`${USERS_SCORES_CLASS}--fadeout`);
    }, 8000);
  };

  return (
    <div
      className={USERS_SCORES_CLASS + " " + usersScoreFadeClass}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <div className={USERS_SCORES_CLASS + "__currently-playing"}>
        Currently playing
      </div>
      <div className={USERS_SCORES_CLASS + "__header-table"}>
        <table cellPadding="0" cellSpacing="0" border={0}>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Score</th>
            </tr>
          </thead>
        </table>
      </div>
      <div
        className={USERS_SCORES_CLASS + "__users-table"}
        style={{
          height: `${height - 87}px`,
        }}
      >
        <table cellPadding="0" cellSpacing="0" border={0}>
          <tbody>
            {playingUsers &&
              playingUsers.map((u, i) => {
                return (
                  <tr
                    className={
                      u._id === user._id
                        ? `${USERS_SCORES_CLASS}__users-table-main-user`
                        : ""
                    }
                    key={i}
                  >
                    <td>{i + 1}</td>
                    <td>{u.name}</td>
                    <td>{u.score}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserScoreOverview;
