import React, { FC } from "react";
import { Loader } from "../loader/Loader";
import "./userpendingloader.style.scss";
type Props = {
  caption: string;
};

export const UserPendingLoader: FC<Props> = ({ caption }) => {
  return (
    <main className="userpendingloader">
      <Loader />
      <span className="userpendingloader__caption">{caption}</span>
    </main>
  );
};
