import React, { Fragment, useState, FC, useEffect } from "react";
import axios from "axios";
import config from "../../modules/config";
import { connect } from "react-redux";
import { getUser } from "../../state/actions";
import { ThemeProvider } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";
import { theme } from "../MuiTheme";
import { UserProfileData } from "../../types/UserProfileData";
import Select from "react-select";
const locationSelectcustomStyles = {
  option: (provided) => ({
    ...provided,
    color: 'black',
  }),
};
type Props = {
  user: UserProfileData;
  getUser: () => void;
  setToggleState: (name: string, toggle: boolean) => void;
  toggle: boolean;
  name: string;
};

const ProfileCityForm: FC<Props> = ({
  user,
  getUser,
  toggle,
  setToggleState,
  name,
}) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  
  const handleInputChange = (inputValue: string) => {
    if (!inputValue) {
      setCityOptions([]);
      return;
    }
    axios
      .get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${inputValue}`
      )
      .then((response) => {
        const newOptions = response.data.map((item) => ({
          label: item.display_name,
          value: item.display_name,
        }));
        setCityOptions(newOptions);
      })
      .catch((error) =>
        console.error("Error fetching data from OSM Nominatim:", error)
      );
  };

  const handleChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const selectedAddress = selectedCity ? selectedCity.value : "";

    const body = {
      city: selectedAddress,
    };
    let authorizedHeader = config.AuthorizationHeader();
    axios
      .post(
        `${process.env.REACT_APP_CROQEE_SERVER_URL}/api/updateuser/` + user._id,
        body,
        authorizedHeader
      )
      .then(() => {
        getUser();
        setToggleState(name, false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Fragment>
      <div className="profile__userInfo">
        <span>City</span>
        <div className="profile__userInfo__container">
          {toggle ? (
            <form
              className="profile__userInfo__container__form"
              onSubmit={onSubmitHandler}
            >
              <ThemeProvider theme={theme}>
                <Select
                  required={true}
                  value={selectedCity}
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  options={cityOptions}
                  placeholder="Search for a location..."
                  className="profile__userInfo-location-search-input"
                  styles={locationSelectcustomStyles}
                />
              </ThemeProvider>
              <button
                type="submit"
                className="profile__userInfo__container__form__btn"
              >
                Update
              </button>
            </form>
          ) : (
            <Fragment>
              <Typography variant="body2" style={{ fontSize: "1rem" }}>
                {user.city ? (user.city.split(',')?.[0] || user.city) : `Add your city`}
              </Typography>
              <a onClick={() => setToggleState(name, true)}>Edit</a>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const user = state.user;
  return { user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCityForm);
