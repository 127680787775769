import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setActiveModel } from '../../../state/actions';
import { Model } from '../DrawingModel/DrawingModel';
import "./modelselector.style.scss";

const MODEL_SELECTOR_CLASS = 'model-selector';
const MODEL_SELECTOR_ANATOMY_CLASS = 'model-selector__anatomy';
const MODEL_SELECTOR_STILL_LIFE_CLASS = 'model-selector__still-life';
type Props = {
  showTimer: boolean;
  timerDone: boolean;
  leftHand: boolean;
  height: number;
  activeModel: {
    model: Model | string;
  }
  setActiveModel: ({model, isDrawn}:any) => void;
}

const  ModelSelector = ({ showTimer, timerDone, leftHand, height, activeModel, setActiveModel}: Props) => {
  const [modelSelectClass, setModelSelectClass] = useState('');
  useEffect(()=> {
    document.addEventListener('scroll', () => trackScrolling());

  }, []);
  
  useEffect(()=> {
    if (showTimer) {
        setModelSelectClass(`${MODEL_SELECTOR_CLASS}--hide`) 
    }
    if (timerDone) {
        setModelSelectClass( `${MODEL_SELECTOR_CLASS}`)
    }
  },[timerDone, showTimer])

  const isBottom =(el) =>  {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

 const  trackScrolling = () => {
    const element = document.getElementsByClassName('drawing-model')[0];

    if (
      element &&
      isBottom(element) &&
      !leftHand && timerDone
    ) {
      setModelSelectClass(`${MODEL_SELECTOR_CLASS}--move-right`)
      document.removeEventListener('scroll', trackScrolling);
    }
  };
  const setModelToStillLife = () =>{
    setActiveModel({
      model: 'stillLife',
      isDrawn: false,
    });
  }

 const setModelToAnatomy = () => {
    setActiveModel({
      model: 'anatomy',
      isDrawn: false,
    });
  }

    return (
      <div
        className={`${MODEL_SELECTOR_CLASS} ${modelSelectClass}`}
        style={{
          top: `${(height - 236) / 2 - 15}px`,
        }}
      >
        <span
          className={`${MODEL_SELECTOR_ANATOMY_CLASS} ${activeModel.model === 'anatomy' &&
            `${MODEL_SELECTOR_ANATOMY_CLASS}--active`
            }`}
          onClick={() => 
            setModelToAnatomy()
          }
        />
        <span
          className={`${MODEL_SELECTOR_STILL_LIFE_CLASS} ${activeModel.model === 'stillLife' &&
            `${MODEL_SELECTOR_STILL_LIFE_CLASS}--active`
            }`}
          onClick={() => 
            setModelToStillLife()
          }
        />
      </div>
    );
}

const mapStateToProps = (state) => {
  const { showTimer, timerDone, leftHand, activeModel } = state;
  return { showTimer, timerDone, leftHand, activeModel };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveModel: (payload) => dispatch(setActiveModel(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelSelector);
