import React from "react";
import { connect } from "react-redux";
import "./canvasteststartoverlay.style.scss";
const CANVAS_TEST_START_OVERLAY_CLASS = "canvas-test-start-overlay";

type Props = {
  canvasHeight: number;
};

const CanvasTestStartOverlay = ({ canvasHeight }: Props) => {
  return (
    <span
      className={CANVAS_TEST_START_OVERLAY_CLASS}
      style={{
        top: `${canvasHeight / 2 - 40}px`,
      }}
    >
      Draw the model here
    </span>
  );
};

const mapStateToProps = (state) => {
  const { canvasHeight } = state;
  return {
    canvasHeight,
  };
};

export default connect(mapStateToProps, {})(CanvasTestStartOverlay);
