import React, { useState, FC } from "react";
import { Button, Card, TextField, Typography } from "@mui/material/";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../components/MuiTheme";
import config from "../../modules/config";
import axios from "axios";
import { InputChangeEvent } from "../../common/model/InputChangeEvent";
import Layout from "../../components/layout/layout";
import "./forgot-password.style.scss";

export const ForgotPassword: FC = () => {
  const [errors, setError] = useState();
  const [isLoading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [emailSent, setEmailSent] = useState(false);

  const onChange = (e: InputChangeEvent): void => {
    let input = e.target;
    setLoading(false);
    setEmail({ email: input.value });
  };
  const onSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;
    const isValid = form.checkValidity();
    const formData = new FormData(form) as any;
    //TODO:  figure out the type for validationMessages
    const validationMessages: Record<string, unknown> | any = Array.from(
      formData.keys()
    ).reduce((acc: any, key: any) => {
      acc[key] = form.elements[key].validationMessage;
      return acc;
    }, {});

    if (!isValid) {
      let msg = validationMessages.email.split(".");
      setError(msg[0]);
    } else {
      setLoading(true);
      let UnAthorizedHeader = config.UnAthorizedHeader();
      axios
        .post(
          `${process.env.REACT_APP_CROQEE_SERVER_URL}/auth/account`,
          email,
          UnAthorizedHeader
        )
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setEmailSent(true);
            setSuccessMessage(
              "Please check your email address and follow the instructions."
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          setSuccessMessage("Something went wrong!");
        });
    }
  };

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <Card className={"forgot-password"} variant="outlined" square>
          <div className="container">
            <form
              action="/"
              onSubmit={onSubmit}
              className="auth_form"
              noValidate
            >
              {!successMessage && (
                <h3 className={"forgot-password__heading"}>
                  {" "}
                  Enter your email address
                </h3>
              )}
              {successMessage && (
                <Typography
                  variant="body1"
                  className="forgot-password__success-message"
                >
                  {successMessage}
                </Typography>
              )}
              {!emailSent && (
                <>
                  <TextField
                    required
                    label="Email"
                    id="email"
                    name="email"
                    type="email"
                    onChange={onChange}
                    placeholder="Your Email address"
                    error={Boolean(errors !== undefined)}
                    helperText={errors}
                    className={"forgot-password__input"}
                  />

                  <Button
                    variant="outlined"
                    disabled={isLoading}
                    type="submit"
                    className={"forgot-password__submit"}
                  >
                    {isLoading ? "Sending..." : "Send Link"}
                  </Button>
                </>
              )}
            </form>
          </div>
        </Card>
      </ThemeProvider>
    </Layout>
  );
};
