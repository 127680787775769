import React from 'react';
import { connect } from 'react-redux';
import {
  setActiveModel,
} from '../../../state/actions';
import { Model } from '../../model/DrawingModel/DrawingModel';
import "./canvasretryoverlay.style.scss";

export const CANVAS_RETRY_OVERLAY_CLASS = 'canvas-retry-overlay';
type Props = {
  setActiveModel: (activeModel:Model) => void;
  activeModel: Model;
  canvasWidth: number;
  canvasHeight: number;
  navigateToClubPage: () => void;
};


const CanvasRetryOverlay = ({setActiveModel,activeModel, canvasWidth, canvasHeight, navigateToClubPage }: Props) => {

  const retryDrawing = () => {
    setActiveModel({
      ...activeModel,
      isDrawn: false,
    });
  }

    return (
      <div
        className={
          !activeModel.isDrawn
            ? `${CANVAS_RETRY_OVERLAY_CLASS} ${CANVAS_RETRY_OVERLAY_CLASS}'--fadeout'`
            : `${CANVAS_RETRY_OVERLAY_CLASS} ${CANVAS_RETRY_OVERLAY_CLASS}'--fadein'`
        }
        style={{
          width: `${canvasWidth}px`,
          height: `${canvasHeight}px`,
          marginBottom: `-${canvasHeight}px`,
        }}
      >
        <button
          onClick={() => navigateToClubPage()}
          className={CANVAS_RETRY_OVERLAY_CLASS + '-compete-button'}
        >
          Draw more models and compete
        </button>

        <h3 
          className={CANVAS_RETRY_OVERLAY_CLASS + '-or'}
        > OR </h3>

        <button
          onClick={() => retryDrawing()}
          className={CANVAS_RETRY_OVERLAY_CLASS + '-retry-button'}
        >
          Retry
        </button>
      </div>
    );
}

const mapStateToProps = (state) => {
  const {
    activeModel,
    canvasWidth,
    canvasHeight,
  } = state;
  return {
    activeModel,
    canvasWidth,
    canvasHeight,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveModel: (payload) => dispatch(setActiveModel(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasRetryOverlay);