import React, { FC } from "react";
import { Links } from "../navbar/Links";
import { Social } from "../navbar/Social";
import { ContactInfo } from "../navbar/ContactInfo";
import { ActionBtnNav } from "../navbar/ActionBtnNav";
import { APP_URL } from "../navbar/NavBar";
import logoImg from "../../images/logo-vw.svg";
import "./footer.style.scss";

export const Footer: FC = (props) => {
  return (
    <footer className="footer">
      <div className="footer-top">
        {/* <div className="footer-top--newsLetter">
          <h3>Draw, compete, improve your anatomy drawing skills</h3>
          <div className="footer-top--newsLetter-form">
            <form>
              <div className="footer-top--newsLetter-form-btn">
                <ActionBtnNav
                  btnText={"Start drawing"}
                  onclick={() => {
                    window.location.href = APP_URL;
                  }}
                />
              </div>
            </form>
          </div>
        </div> */}
        <div className="footer-top-contact">
          <img src={logoImg} alt="logo" />
          {/* <ContactInfo /> */}
        </div>

        <div className="footer-top-links">{/* <Links /> */}</div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-social">{/* <Social /> */}</div>
        <div className="footer-bottom-copy">
          &#169;Copyright Croqee {new Date().getFullYear()}. All rights
          reserved.
        </div>
        <div className="footer-bottom-love">Made by Croqee team with love</div>
      </div>
    </footer>
  );
};
