import React, { Fragment, useState, useEffect, FC } from "react";
import axios from "axios";
import config from "../../modules/config";
import { connect } from "react-redux";
import { getUser } from "../../state/actions";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Chip, Button, Avatar, PropTypes } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileAvatar } from "./ProfileAvatar";
import imageCompression from "browser-image-compression";
import { UserProfileData } from "../../types/UserProfileData";
import { ProfileAvatarAnonymous } from "./ProfileAvatarAnonymous";



type ImageUpdateMessegae = {
  success: null | boolean;
  icon: JSX.Element;
  msg: string;
  color: string;
};

type Props = {
  user: UserProfileData;
  getUser: () => void;
  setToggleState: (name: string, toggle: boolean) => void;
  name: string;
  toggle: boolean;
};

const ProfileImgForm: FC<Props> = ({
  user,
  setToggleState,
  getUser,
  name,
  toggle
}) => {
  const [image, setImage] = useState<File | Blob | null>();
  const [userImage, setuserImage] = useState<string | null>();
  const [imagePath, setImagePath] = useState<string | null>();
  const [message, setMessage] = useState<ImageUpdateMessegae>({
    success: null,
    icon: <DoneIcon />,
    msg: "",
    color: ""
  });

  const getUserImage = e => {
    setuserImage(`${process.env.REACT_APP_CROQEE_SERVER_URL}/user-image/` + e.image_data);
  };

  const onChangeUpload = async e => {
    setImagePath(URL.createObjectURL(e.target.files[0]));
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 250,
      useWebWorker: true,
      file: "File"
    };
    try {
      const compressedFile = await imageCompression(e.target.files[0], options);
      setImage(compressedFile);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImage = () => {
    const imageFormObj = new FormData();
    imageFormObj.append("image_data", image);
    const athorizedHeader = config.AuthorizationHeader();
    axios
      .post(`${process.env.REACT_APP_CROQEE_SERVER_URL}/images/uploaduserimg/` + user._id, imageFormObj, athorizedHeader)
      .then(res => {
        getUser();
        setToggleState(name, false);
        setImage(null);
        setImagePath(null);
        setMessage({
          success: true,
          icon: <DoneIcon />,
          msg: "Updated.",
          color: "primary"
        });
      })
      .catch(err => {
        setMessage({
          success: false,
          icon: <CloseIcon />,
          msg: "Update Failed.",
          color: "secondary"
        });
      });
  };
  useEffect(() => {
    if (user && user.img) {
      getUserImage(user.img);
    }
  }, [image, user]);

  return (
    <Fragment>
        <div className="profile__img-name-wrapper__img">
          {toggle ? (
            <Fragment>
              <input
                accept="image/*"
                id="raised-button-file"
                type="file"
                style={{ display: "none" }}
                onChange={e => onChangeUpload(e)}
              />
              <label htmlFor="raised-button-file">
                {!imagePath || imagePath === "" ? (
                  <Avatar sx={{
                    width: 128,
                    height:128
                  }}>
                    <AddAPhotoIcon style={{ color: '#06b3cf' }} />
                  </Avatar>
                ) : (
                  <ProfileAvatar imageSrc={imagePath} />
                )}
              </label>
              <Button
                className="profile__img-name-wrapper__img-link"
                onClick={e => uploadImage()}
                disabled={imagePath === ""}
              >
                Upload
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              {image ? (
                <Avatar
                  src={userImage}
                  alt="profile image"
                />
              ) : (
                <ProfileAvatarAnonymous />
              )}

              <a
                className="profile__img-name-wrapper__img-link"
                onClick={() => {
                  setToggleState(name, true);
                }}
              >
                Change
              </a>
            </Fragment>
          )}
          {message.success !== null && (
            <Chip
              size="small"
              label={message.msg}
              disabled
              icon={message.icon}
              color={message.color as any}
              variant="outlined"
            />
          )}
        </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  const user = state.user;
  const image = state.user.img;
  return { user, image };
};
const mapDispatchToProps = dispatch => {
  return {
    getUser: () => dispatch(getUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImgForm);
