import React, { FC } from "react";
import { OnclickElement } from "../../common/model/EventModels";
import "./navbar.style.scss";

type Props = {
  onclick?: OnclickElement;
  btnText: string;
  endIcon?: any;
};

export const ActionBtnNav: FC<Props> = ({ onclick, btnText, endIcon }) => (
  <button className="nav-links-btn" onClick={onclick}>
    <span className="nav-links-btn-text">
      {btnText}
      {endIcon}
    </span>
  </button>
);
