import React, { FC } from "react";

type Props = {
  imageSrc?: string;
};

export const ProfileAvatar: FC<Props> = ({ imageSrc }) => {
  const largeStyle = {
    width: "96px",
    height: "96px",
    backgroundColor: "black",
  };

  return <img style={largeStyle} src={imageSrc} alt="Avatar" />;
};