import React, { memo, useCallback } from "react";
import { connect } from "react-redux";
import {
  setTimer,
  invokeScore,
  setImageProcessing,
  setTimerDone,
  setCanvasDimension,
  setInnerModelDimension,
  setIsDrawingStarted,
} from "../../../state/actions";
import { Loader } from "../../loader/Loader";
import { calcCanvasAndModelDim } from "../../../lib/CalcCanvasAndModelDim";
import { throttle } from "../../../lib/Throttle";
import { preventDefaultMouseTouchEvents } from "../../../lib/preventDefaultMouseTouchEvents";
import CanvasRetryOverlay from "../CanvasRetryOverlay/CanvasRetryOverlay";
import DrawingResult from "../DrawingResult/DrawingResult";
import CanvasStartOverlay from "../CanvasStartOverlay/CanvasStartOverlay";
import CanvasBrushSizeSlider from "../CanvasBrushSizeSlider/CanvasBrushSizeSlider";
import { useState, useRef, useEffect } from "react";
import "./canvas.style.scss";
import { Model } from "../../model/DrawingModel/DrawingModel";
import { HOME_DRAWING_GIVEN_TIME } from "../../../templates/home";

export const CANVAS_CLASS = "canvas";
export const CANVAS_ID = "drawing-canvas";
const styles: any = {
  canvas: {
    cursor: "crosshair",
  },
};

type Props = {
  timerDone: boolean;
  shouldResetCanvas: boolean;
  setIsDrawingStarted: (bool: boolean) => void;
  setShouldResetCanvas: (bool: boolean) => void;
  leftHand: boolean;
  setTimerDone: (bool: boolean) => void;
  setTimer: ({ showTimer, timer }: any) => void;
  canvasWidth: number;
  setCanvasDimension: ({ canvasWidth, canvasHeight }: any) => void;
  setInnerModelDimension: ({ innerModelWidth, innerModelHeight }: any) => void;
  setHasUserDrawnOnCanvas: (bool: boolean) => void;
  canStartDrawing: boolean;
  isInHomePage: boolean;
  canvasHeight: number;
  imageProcessing: boolean;
  isCompeting: boolean;
  canJoinClub: boolean;
  activeModel: {
    isDrawn: boolean;
  };
  navigateToClubPage: () => void;
  imgPath: string;
  model: Model;
  baseURL?: string;
  setBaseUrl: (baseURL: string) => void;
  brushSize: number;
};

const Canvas = memo((props: Props) => {
  const canvasRef = useRef(null);

  const [countDown, setCountDown] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [drawing, setDrawing] = useState(false);
  const [currentColor, setCurrentColor] = useState("black");
  const [usingTouch, setUsingTouch] = useState(false);
  const [lineWidth, setLineWidth] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  

  const startCountDown = () => {
    if (countDown > 1) {
      setTimeout(() => {
        setCountDown(countDown - 1);
        startCountDown();
      }, 1000);
    } else {
      return false;
    }
  };

  const setCanvasSize = useCallback(() => {
    const { canvasWidth, canvasHeight, innerModelWidth, innerModelHeight } =
      calcCanvasAndModelDim(() => {
        styles.canvas = {
          ...styles.canvas,
          marginRight: "0",
        };
      });
    props.setCanvasDimension({
      canvasWidth,
      canvasHeight,
    });
    props.setInnerModelDimension({
      innerModelWidth,
      innerModelHeight,
    });
    reset();
  }, [props.canvasWidth, props.canvasHeight, props.leftHand]);

  const reset = useCallback(() => {
    const ratio = props.canvasWidth && props.canvasWidth < 500 ? 1800 : 700;
    const newLineWidth = props.brushSize ? props.brushSize : (2 * props.canvasWidth) / ratio;
    setLineWidth(newLineWidth);
  
    const canvas = canvasRef.current;
    if (canvas && canvas.getContext) {
      const context = canvas.getContext("2d");
      context.fillStyle = "white";
      context.fillRect(0, 0, props.canvasWidth, props.canvasHeight);
      preventDefaultMouseTouchEvents(canvas);
    }
  }, [props.brushSize, props.canvasHeight, props.canvasWidth]);
  
  const drawLine = useCallback((
    x0, y0, x1, y1, color, emit, force = 1
  ) => {
    if (props.canStartDrawing && props.timerDone) {
      if (props.isInHomePage) {
        props.setTimer({ showTimer: true, timer: HOME_DRAWING_GIVEN_TIME });
        props.setTimerDone(false);
      } else {
        props.setHasUserDrawnOnCanvas(true);
      }
      setFadeOut(true);
    }
  
    const context = canvasRef.current?.getContext("2d");
    if (context) {
      context.beginPath();
      context.moveTo(x0, y0);
      context.lineTo(x1, y1);
      context.strokeStyle = color;
      context.lineWidth = force * lineWidth;
      context.stroke();
      context.closePath();
    }
  
    if (!emit) return;
  }, [props, lineWidth]);
  
  const onMouseDown = useCallback((e) => {
    // e.preventDefault();
    if (props.canStartDrawing) {
      setCurrentX(e.clientX);
      setCurrentY(e.clientY);
      setDrawing(true);
      props.setIsDrawingStarted(true);
    }
  }, [props.canStartDrawing]);

  const onMouseUp = useCallback((e) => {
    setCurrentX(e.clientX);
    setCurrentY(e.clientY);
    setDrawing(false);
  }, []);

  const onMouseMove = useCallback(throttle((e) => {
    if (!drawing) {
      return;
    }
    if (usingTouch) {
      setUsingTouch(false);
    }
    setCurrentX(e.clientX);
    setCurrentY(e.clientY);
    drawLine(
      currentX - canvasRef.current.getBoundingClientRect().left,
      currentY - canvasRef.current.getBoundingClientRect().top,
      e.clientX - canvasRef.current.getBoundingClientRect().left,
      e.clientY - canvasRef.current.getBoundingClientRect().top,
      currentColor,
      true
    );
  }, 5), [drawing, currentX, currentY, currentColor]);

  const onTouchMove = useCallback(throttle((e) => {
    e.preventDefault();
    if (!usingTouch) {
      setUsingTouch(true);
    }
    if (!drawing) {
      return;
    }
    drawLine(
      currentX - canvasRef.current.getBoundingClientRect().left,
      currentY - canvasRef.current.getBoundingClientRect().top,
      e.touches[0].clientX - canvasRef.current.getBoundingClientRect().left,
      e.touches[0].clientY - canvasRef.current.getBoundingClientRect().top,
      currentColor,
      true,
      e.touches[0].force
    );

    setCurrentX(e.touches[0].clientX);
    setCurrentY(e.touches[0].clientY);
  }, 5), [drawing, currentX, currentY, currentColor, usingTouch]);

  const handleBrushSizeChange = (lineWidth: number) => {
    setLineWidth(lineWidth);
  };


  useEffect(() => {
    const setCanvasSizeOnResize = () => {
      if (props.timerDone) {
        setCanvasSize();
      }
    };
    window.addEventListener("resize", setCanvasSizeOnResize);
    return () => {
      window.removeEventListener("resize", setCanvasSizeOnResize);
      props.setTimerDone(true);
      props.setTimer({ showTimer: false, timer: HOME_DRAWING_GIVEN_TIME });
    };
  }, []);

  useEffect(() => {
    if (props.shouldResetCanvas) {
      reset();
      setTimeout(() => {
        props.setIsDrawingStarted(false);
      }, 5000);
      props.setShouldResetCanvas(false);
    }
  }, [props.shouldResetCanvas]);

  useEffect(() => {
    setCanvasSize();
  }, [props.leftHand]);
  
  useEffect(() => {
    setIsDrawingStarted(true);
    setCanvasSize();
  }, [props.canvasWidth, props.canvasHeight, setCanvasSize]);

  const { canvasWidth, canvasHeight, isCompeting } = props;
  const side = props.leftHand ? `${CANVAS_CLASS}--left-hand` : "";

  return (
    <>
      {lineWidth > 0 && (
        <div
          className={
            side
              ? `${CANVAS_CLASS}__brush-size ${CANVAS_CLASS}__brush-size--left`
              : `${CANVAS_CLASS}__brush-size`
          }
        >
          <CanvasBrushSizeSlider
            defaultValue={lineWidth}
            handleBrushSizeChange={handleBrushSizeChange}
            size={lineWidth}
            canvasWidth={canvasWidth}
          />
        </div>
      )}
      {canvasWidth > 0 && canvasHeight > 0 && (
        <div
          className={CANVAS_CLASS + " " + side}
          /* tslint:disable */
          style={{
            width: `${canvasWidth}px`,
            height: `${canvasHeight}px`,
          }}
        >

          {props.imageProcessing && <Loader canvasBlocker={true} />}

          <CanvasStartOverlay
            canStartDrawing={props.canStartDrawing}
            canJoinClub={props.canJoinClub}
            isCompeting={isCompeting}
            fadeOut={fadeOut}
          />

          {props.activeModel.isDrawn && !isCompeting && (
            <CanvasRetryOverlay navigateToClubPage={props.navigateToClubPage} />
          )}
          {props.baseURL && (
            <DrawingResult
              imgPath={props.imgPath}
              model={props.model}
              baseURL={props.baseURL}
              isInHomePage={false}
            />
          )}

          <canvas
            id={CANVAS_ID}
            style={styles.canvas}
            className={`${CANVAS_CLASS}__area`}
            width={`${canvasWidth}px`}
            height={`${canvasHeight}px`}
            ref={canvasRef}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={throttle(onMouseMove, 5)}
            onMouseOut={onMouseUp}
            onTouchStart={onMouseDown}
            onTouchMove={throttle(onTouchMove, 5)}
            onTouchEnd={onMouseUp}
          />
        </div>
      )}
    </>
  );
});

const mapStateToProps = (state: any) => {
  const {
    timer,
    showTimer,
    timerDone,
    imageProcessing,
    currentScore,
    leftHand,
    startImageProcessing,
    activeModel,
    canvasWidth,
    canvasHeight,
    isDrawingStarted,
    brushSize,
  } = state;
  return {
    timer,
    showTimer,
    timerDone,
    imageProcessing,
    currentScore,
    leftHand,
    startImageProcessing,
    activeModel,
    canvasWidth,
    canvasHeight,
    isDrawingStarted,
    brushSize,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setTimer: (payload: any) => dispatch(setTimer(payload)),
    invokeScore: (payload: any) => dispatch(invokeScore(payload)),
    setImageProcessing: (payload: any) => dispatch(setImageProcessing(payload)),
    setTimerDone: (payload: any) => dispatch(setTimerDone(payload)),
    setCanvasDimension: (payload: any) => dispatch(setCanvasDimension(payload)),
    setInnerModelDimension: (payload: any) =>
      dispatch(setInnerModelDimension(payload)),
    setIsDrawingStarted: (payload: any) =>
      dispatch(setIsDrawingStarted(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
