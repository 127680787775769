import React, { Fragment, FC } from "react";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { OnChangeInput } from "../../common/model/EventModels";
import "./profileForm.style.scss";
import { ProfileError } from "../../types/Profile";

type Props = {
  onchange: OnChangeInput;
  onsubmit: any;
  setToggleState: (name: string, toggle: boolean) => void;
  name: string;
  toggle: boolean;
  userProfileData: string;
  state: string;
  profileError: ProfileError;
};

export const ProfileForm: FC<Props> = ({
  onchange,
  onsubmit,
  setToggleState,
  name,
  toggle,
  userProfileData,
  state,
  profileError,
}) => {
  return (
    <Fragment>
      <div className="profile__userInfo">
        <span>{name.charAt(0).toUpperCase() + name.slice(1)}</span>
        <div className="profile__userInfo__container">
          {toggle ? (
            <form
              noValidate
              className="profile__userInfo__container__form"
              onSubmit={onsubmit(name)}
            >
              <TextField
                className="profile__userInfo__container__form__input"
                onChange={onchange}
                name={name}
                id="filled-error"
                inputProps={{
                  pattern:
                    name === "website"
                      ? `https://.*`
                      : name === "behance"
                      ? `https://${name}.net/.*`
                      : `https://${name}.com/.*`,
                }}
                error={
                  profileError
                    ? Boolean(Object.keys(profileError).length !== 0)
                    : false
                }
                helperText={
                  profileError &&
                  profileError[`${name}`] &&
                  profileError[`${name}`]
                }
                defaultValue={state}
                placeholder={
                  !state
                    ? name === "behance"
                      ? `https://${name}.net/**`
                      : `https://${name}.com/**`
                    : ""
                }
              />
              <button
                type="submit"
                className="profile__userInfo__container__form__btn"
              >
                Update
              </button>
            </form>
          ) : (
            <Fragment>
              <Typography variant="body2" style={{ fontSize: "1rem" }}>
                {userProfileData ? (
                  <a href={userProfileData} className="profile__links__data">
                    {userProfileData} <span></span>
                  </a>
                ) : (
                  `Link your ${name} account`
                )}
              </Typography>

              <a
                onClick={() => {
                  setToggleState(name, true);
                }}
              >
                Edit
              </a>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};
