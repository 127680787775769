import React, { FC, useState } from "react";
import { Slider, Box } from '@mui/material';
import { connect } from "react-redux";
import {
  setBrushSize
} from "../../../state/actions";

const wrapperStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  color: '#5d5e5e'
}
const sliderStyles = {
  color: "#000",
  position: "absolute",
  zIndex: "1000",
  width: "150px",
  top: "70px",
}

type Props = {
  defaultValue: number;
  handleBrushSizeChange: (value: number) => void;
  size: number;
  canvasWidth: number;
  setBrushSize: (payload: number) => void;
};

const CanvasBrushSizeSlider: FC<Props> = ({
  defaultValue,
  handleBrushSizeChange,
  size,
  canvasWidth,
  setBrushSize
}) => {
  const [brushSizeSlider, setBrushSizeSlider] = useState(false);
  const buttonStyles = {
    width: 6 * size,
    height: 6 * size,
    borderRadius: '50%',
    backgroundColor: "#000",
    margin: "auto",
  }

  const maxSize = canvasWidth * 0.006;
  const minSize = canvasWidth * 0.00002;
  return <Box
    onClick={() => setBrushSizeSlider(!brushSizeSlider)}
    sx={wrapperStyles}>
    Brush size
    <Box>
      <Box
        sx={buttonStyles}
      >
      </Box>
      {size.toFixed(2)}
    </Box>
    {brushSizeSlider &&
      < Slider
        sx={sliderStyles}
        defaultValue={defaultValue}
        step={minSize}
        min={minSize}
        max={maxSize}
        onChange={(e: Event) => {
          const brushSize = parseFloat((e.target as HTMLInputElement).value)
          handleBrushSizeChange(brushSize)
          setBrushSize(brushSize)
        }
        }
      />

    }
  </Box>
}

const mapStateToProps = state => {
  const {
  } = state;
  return {
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setBrushSize: payload => dispatch(setBrushSize(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasBrushSizeSlider)