import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { InputChangeEvent } from "../../common/model/InputChangeEvent";
import { InputFormEvent } from "../../common/model/InputFormEvent";
import { UserAuthRequirements } from "../../types/user";
import "./loginForm.style.scss";

type Props = {
  onSubmit: (e: InputFormEvent) => void;
  onChange: (e: InputChangeEvent) => void;
  errors: any;
  successMessage: string;
  user: UserAuthRequirements;
};

export const LoginForm: Props | any = ({
  onSubmit,
  onChange,
  errors,
  successMessage,
  user
}) => (
    <div className="container">
      <form action="/" onSubmit={onSubmit} className="auth_form" noValidate>
        <h2 className="card-heading">Login to Croqee</h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        <TextField
          required
          label="Email"
          id="email"
          name="email"
          onChange={onChange}
          // value={user?.email}
          placeholder="Your Email address"
          error={Boolean(errors && errors.summary)}
          helperText={errors?.email ? errors.email : errors.summary}
          className={"auth_form-input"}
        />
        <TextField
          required
          label="Password"
          id="password"
          type="password"
          name="password"
          placeholder="Your Password "
          onChange={onChange}
          // value={user?.password}
          error={Boolean(errors && errors.summary)}
          helperText={errors?.password ? errors.password : errors.summary}
          className={"auth_form-input"}
        />
        <Button className={"auth_form-input"} variant="outlined" type="submit">
          Login
        </Button>
      </form>
    </div>
);
