import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Canvas, { CANVAS_ID } from "../../components/canvas/Canvas/Canvas";
import "./home.style.scss";
import {
  getUser,
  setTimer,
  setImageProcessing,
  invokeScore,
  setActiveModel,
  setDrawingEvaluationError,
} from "../../state/actions";
// import Timer from "../../child/timer/Timer";
import { ActiveModel } from "../../types/model";
import { DRAWING_EVALUATION_ERROR } from "../../constants/site-content";
import InteractiveDrawingModel from "../../components/model/InteractiveDrawingModel/InteractiveDrawingModel";
import Timer from "../../components/timer/Timer";
import Layout from "../../components/layout/layout";
import { DrawingEnvironment } from "../../components/drawingenvironment/DrawingEnvironment";
import { ActionBtnNav } from "../../components/navbar/ActionBtnNav";
import { APP_URL } from "../../components/navbar/NavBar";
import { useNavigate } from "react-router";
export const HOME_DRAWING_GIVEN_TIME = 60;

const CROQEE_VIDEO_SECTION_CLASS = "croqee-video-section";

type ITimer = {
  showTimer: boolean;
  timer: number;
};

export interface HomePageProps extends React.Component<any> {
  getUser?: () => void;
  setImageProcessing?: (bool: boolean) => void;
  setTimer?: (timer: ITimer) => void;
  setTimerDone?: (bool: boolean) => void;
  activeModel?: ActiveModel;
  canvasWidth?: number;
  canvasHeight?: number;
  invokeScore?: (score: number) => void;
  startImageProcessing?: boolean;
  leftHand?: boolean;
  showTimer?: boolean;
  setActiveModel?: (activeModel: ActiveModel) => void;
  path?: any;
}
export interface MappedProps {
  setDrawingEvaluationError?: (error: string) => void;
}

const Home = (props: (HomePageProps & MappedProps) | any): JSX.Element => {
  const [baseURL, setBaseURL] = useState("");
  const [shouldResetCanvas, setShouldResetCanvas] = useState(false);
  const initialRef: any = null;
  const interactiveModel = useRef(initialRef);
  const navigate = useNavigate();

  useEffect(() => {
    props.getUser();
  }, []);

  useEffect(() => {
    if (props.startImageProcessing) {
      if (props.startImageProcessing) {
        sendDrawing();
      }
    }
  }, [props.startImageProcessing]);

  const getOutlineScreenShot = () => {
    const outlineBase64 = interactiveModel?.current?.getOutlineScreenShot();
    return outlineBase64;
  };
  const getModelScreenShot = () => {
    const outlineBase64 = interactiveModel?.current?.getModelScreenShot();
    return outlineBase64;
  };
  const navigateToClubPage = () => {
    navigate(APP_URL);
  };
  const sendDrawing = () => {
    const canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement;
    if (canvas) {
      props.setImageProcessing(true);
      const dataURL = canvas
        .toDataURL("image/jpeg", 0.8)
        .replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
      let modelOutlineDataURL = getOutlineScreenShot();
      let modelDataURL = getModelScreenShot();
      props.setActiveModel({
        modelDataURL: modelDataURL,
      });
      modelOutlineDataURL = modelOutlineDataURL.replace(
        /^data:image\/(png|jpg|jpeg);base64,/,
        ""
      );
      props.setTimer({ showTimer: false, timer: 30 });
      const model = "";
      console.log(
        "trig ",
        `${process.env.REACT_APP_CROQEE_SERVER_URL}/send_drawing`
      );

      axios
        .post(`${process.env.REACT_APP_CROQEE_SERVER_URL}/send_drawing`, {
          dataURL: dataURL,
          modelOutlineDataURL: modelOutlineDataURL,
          model: model,
          canvasWidth: props.canvasWidth,
          canvasHeight: props.canvasHeight,
        })
        .then((response) => {
          console.log("recieved", response.data);
          if (response.data.img) {
            console.log("kirekhar ", response.data);
            const score = response.data.score || 0;
            setBaseURL("data:image/png;base64, " + response.data.img);
            props.setImageProcessing(false);
            props.invokeScore(score);
            setShouldResetCanvas(true);
          } else {
            const score = 0;
            props.setDrawingEvaluationError(
              DRAWING_EVALUATION_ERROR.processingError
            );
            props.setImageProcessing(false);
            props.invokeScore(score);
            setShouldResetCanvas(true);
          }
        })
        .catch((_e) => {
          console.error(_e);
          const score = 0;
          props.setDrawingEvaluationError(
            DRAWING_EVALUATION_ERROR.serverConnectionError
          );
          props.setImageProcessing(false);
          props.invokeScore(score);
          setShouldResetCanvas(true);
        });
    }
  };

  const side = props.leftHand ? "model_left_hand" : "";
  return (
    <Layout>
      <div className={CROQEE_VIDEO_SECTION_CLASS}>
        <div className={CROQEE_VIDEO_SECTION_CLASS + "__title"}>
          <h1 className={CROQEE_VIDEO_SECTION_CLASS + "__title-main"}>
            Draw, compete <br></br>improve your anatomy drawing skills
          </h1>
          <div className={CROQEE_VIDEO_SECTION_CLASS + "__title-caption"}>
            A path to excel in Illustration, Comics and Animation
          </div>
          <ActionBtnNav
            btnText={"Start drawing"}
            onclick={() => {
              window.location.href = APP_URL;
            }}
          />
        </div>

        <video
          autoPlay
          muted
          loop
          className={CROQEE_VIDEO_SECTION_CLASS + "__video"}
        >
          <source src={require("../../videos/final_2.mp4")} type="video/mp4" />
        </video>
      </div>
      {/* <DrawingEnvironment side={side}>
        {props.showTimer && <Timer />}
        {props.canvasWidth && props.canvasHeight && (
          <InteractiveDrawingModel
            componentRef={interactiveModel}
            modelUrl={
              "https://raw.githubusercontent.com/Miroslav-H/3D-test/master/resources/female1.gltf"
            }
            allowUserCameraControl={true}
            cameraPosition={{
              x: 0,
              y: 20,
              z: 75,
            }}
            modelScale={5.3}
            controlTarget={{
              x: 0,
              y: 17,
              z: 0,
            }}
            outLineSpec={{
              normalMultiplier: 0.8,
            }}
          />
        )}
        <Canvas
          isInHomePage={true}
          setBaseUrl={setBaseURL}
          shouldResetCanvas={shouldResetCanvas}
          setShouldResetCanvas={setShouldResetCanvas}
          canStartDrawing={true}
          baseURL={baseURL}
          navigateToClubPage={navigateToClubPage}
          model={{
            model: "",
            url: "",
            givenTime: 0,
            isDrawn: false,
            modelDataURL: "",
            threeDModelUrl: "",
            cameraPosition: {
              x: 0,
              y: 0,
              z: 0,
            },
            modelScale: 0,
            controlTarget: {
              x: 0,
              y: 0,
              z: 0,
            },
            normalMultiplier: 0,
            outLineSpec: {
              normalMultiplier: 0,
              outlineSharpness: 0,
              normalBias: 0,
              depthMultiplier: 0,
            },
          }}
          setHasUserDrawnOnCanvas={function (bool: boolean): void {
            throw new Error("Function not implemented.");
          }}
          isCompeting={false}
          canJoinClub={false}
          imgPath={""}
        />
      </DrawingEnvironment> */}
    </Layout>
  );
};

const mapStateToProps = (state: any) => {
  const {
    events,
    user,
    showTimer,
    scoreClass,
    currentScore,
    leftHand,
    timerDone,
    startImageProcessing,
    activeModel,
    canvasWidth,
    canvasHeight,
  } = state;
  return {
    events,
    user,
    showTimer,
    scoreClass,
    currentScore,
    leftHand,
    timerDone,
    startImageProcessing,
    activeModel,
    canvasWidth,
    canvasHeight,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getUser: () => dispatch(getUser()),
    setTimer: (payload: any) => dispatch(setTimer(payload)),
    setImageProcessing: (payload: any) => dispatch(setImageProcessing(payload)),
    invokeScore: (payload: any) => dispatch(invokeScore(payload)),
    setActiveModel: (payload: any) => dispatch(setActiveModel(payload)),
    setDrawingEvaluationError: (payload: any) =>
      dispatch(setDrawingEvaluationError(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
