import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserProfile from "./templates/userprofile";
import Signup from "./templates/signup";
import Logout from "./templates/logout";
import Leaderboard from "./templates/leaderboard";
import Compete from "./templates/compete";
import Competes from "./templates/competes";
import Account from "./templates/account";
import Home from "./templates/home";
import Login from "./templates/login";
import ReduxProvider from "./state/wrapper";
import { ForgotPassword } from "./templates/forgot-password/ForgotPassword";
import { ResetPass } from "./templates/reset-password/ResetPassword";

const App = () => {
  return (
    <ReduxProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/userprofile/:userId" element={<UserProfile />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/compete/:drawingField" element={<Compete />} />
          <Route path="/competes" element={<Competes />} />
          <Route path="/account/*" element={<Account />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword/link" element={<ForgotPassword/>} />
          <Route path="/reset/:resetPasswordToken" element={<ResetPass/>} />
        </Routes>
      </Router>
    </ReduxProvider>
  );
};

export default App;
