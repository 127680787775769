import React, { FC, memo, useEffect, useRef, useState } from "react";

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 180) * Math.PI) / 180.0;
    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
    };
};

const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    return [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
};

type Props = {
    score: number;
};

export const ScoreIndicator: FC<Props> = memo(({ score }) => {
    const componentRef = useRef(null);
    const [svgElement, setSvgElement] = useState(null);
    const [textElement, setTextElement] = useState(null);
    const [sliceElement, setSliceElement] = useState(null);
    const size = 170;
    const strokeWidth = size / 8;
    const radius = size / 2 - strokeWidth / 2;

    useEffect(() => {
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute("width", `${size}px`);
        svg.setAttribute("height", `${size}px`);

        // Creating defs
        const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
        const linearGradient = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient");
        linearGradient.setAttribute("id", "gradient");
        const stop1 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
        stop1.setAttribute("stop-color", "#06b3cf");
        stop1.setAttribute("offset", "0%");
        linearGradient.appendChild(stop1);
        const stop2 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
        stop2.setAttribute("stop-color", "#f25767");
        stop2.setAttribute("offset", "100%");
        linearGradient.appendChild(stop2);
        defs.appendChild(linearGradient);
        svg.appendChild(defs);

        // Creating slice
        const slice = document.createElementNS("http://www.w3.org/2000/svg", "path");
        slice.setAttribute("fill", "none");
        slice.setAttribute("stroke", "url(#gradient)");
        slice.setAttribute("stroke-width", strokeWidth+'');
        slice.setAttribute("transform", `translate(${strokeWidth / 2}, ${strokeWidth / 2})`);
        svg.appendChild(slice);

        // Creating text
        const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
        const fontSize = size / 3.5;
        text.setAttribute("x", size / 2 + fontSize / 7.5 + '');
        text.setAttribute("y", size / 2 + fontSize / 4 + '');
        text.setAttribute("font-size", fontSize + '');
        text.setAttribute("fill", "#06b3cf");
        text.setAttribute("text-anchor", "middle");
        text.innerHTML = `${0} `;
        svg.appendChild(text);

        componentRef.current.appendChild(svg);
        setSvgElement(svg);
        setTextElement(text);
        setSliceElement(slice);

        return () => {
            if (componentRef.current) {
                componentRef.current.innerHTML = '';
            }
        };
    }, []);

    useEffect(() => {
        if (!svgElement || !textElement || !sliceElement) {
            return;
        }

        let v = 0;
        const intervalOne = setInterval(() => {
            const p = +(v / score).toFixed(2);
            const a = p < 0.95 ? 2 - 2 * p : 0.05;
            v += a;
            if (v >= +score) {
                v = score;
                clearInterval(intervalOne);
            }
            setValue(v);
        }, 10);

        const setValue = (value) => {
            let c = (value / 100) * 360;
            if (c === 360) c = 359.99;
            const xy = size / 2 - strokeWidth / 2;
            const d = describeArc(xy, xy, xy, 180, 180 + c);

            sliceElement.setAttribute("d", d);
            textElement.innerHTML = `${Math.floor(value)}`;
        };

        return () => {
            clearInterval(intervalOne);
        };
    }, [score, svgElement, textElement, sliceElement, strokeWidth]);

    return (
        <div className="score-indicator" ref={componentRef} data-size="170" data-arrow="down">
        </div>
    );
});
