import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModelSelector from '../ModelSelector/ModelSelector';
import ModelImage from '../ModelImage/ModelImage';
import { OutLineSpec, ThreeDimPosition } from '../InteractiveDrawingModel/InteractiveDrawingModel';
import { User } from '../../../types/user';
import "./drawingmodel.style.scss";
import UserScoreOverview from '../UsersScores/UsersScores';
const DRAWING_MODEL_CLASS = 'drawing-model';
const DRAWING_MODEL_DESCRIPTION = 'drawing model image';

export type Model = {
  model: string;
  url: string;
  givenTime: number;
  isDrawn: boolean;
  modelDataURL?: string;
  threeDModelUrl?: string;
  cameraPosition?: ThreeDimPosition;
  modelScale?: number;
  controlTarget?: ThreeDimPosition;
  normalMultiplier?: number;
  outLineSpec?: OutLineSpec;
}

type Props = {
  model: Model;
  compete: boolean;
  user: User;
  playingUsers: any[]
  showUserScores: boolean;
  canvasWidth: number;
  canvasHeight: number;
  activeModel: Model;
  imgPath: string;
}
const DrawingModel = (props:Props) => {
    const {
      model,
      compete,
      showUserScores,
      playingUsers,
      user,
      canvasWidth,
      canvasHeight,
      activeModel,
      imgPath
    } = props;
    return (
      <React.Fragment>
        {canvasWidth && (
          <div
            className={DRAWING_MODEL_CLASS}
            style={{
              width: `${canvasWidth}px`,
              height: `${canvasHeight}px`,
            }}
          >
            {compete ? (
              <React.Fragment>
                {model.model && (
                  <ModelImage
                    imgPath={model.url || require(`../../../img${imgPath}${model.model}.png`).default}
                    description={DRAWING_MODEL_DESCRIPTION} />
                )}
                {showUserScores && (
                  <UserScoreOverview
                    width={canvasWidth}
                    height={canvasHeight}
                    playingUsers={playingUsers}
                    user={user}
                  />
                )}
              </React.Fragment>
            ) : (
                <React.Fragment>
                  <ModelSelector height={canvasHeight} />
                  {activeModel &&
                    activeModel.model === 'stillLife' ? (
                      <ModelImage
                        imgPath={require('../../../img/compete/still-life/geometrical5.png').default}
                        description={DRAWING_MODEL_DESCRIPTION} />
                    ) : (
                      <ModelImage
                        imgPath={require('../../../img/compete/anatomy/female1.png').default}
                        description={DRAWING_MODEL_DESCRIPTION} />
                    )}
                </React.Fragment>
              )}
          </div>
        )}
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
  const {
    activeModel,
    canvasWidth,
    canvasHeight,
  } = state;
  return {
    activeModel,
    canvasWidth,
    canvasHeight,
  };
};

export default connect(mapStateToProps, {})(DrawingModel);