import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setActiveModel } from '../../../state/actions';
import "./modelpropertiesselector.style.scss";

const MODEL_PROPERTIES_SELECTOR_CLASS = 'model-properties-selector';

type Props = {
  height: number;
  displayOutline: boolean;
  displayModel: boolean;
  toggleDisplayOutline: () => void;
  toggleDisplayModel: () => void;
}
type States = {
  modelSelectClass: string;
}

class ModelPropertiesSelector extends Component<Props, States> {
  modelSelect = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      modelSelectClass: '',
    };
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }


  render() {
    const {
      modelSelectClass,
    } = this.state;
    const {
      height,
      displayOutline,
      displayModel,
      toggleDisplayModel,
    } = this.props;

    return (
      <div
        className={`${MODEL_PROPERTIES_SELECTOR_CLASS} ${modelSelectClass}`}
        style={{
          top: `${(height - 236) / 2 - 15}px`,
        }}
      >
        {/* <span
          className={`${MODEL_PROPERTIES_SELECTOR_CLASS}__outline ${displayOutline &&
            `${MODEL_PROPERTIES_SELECTOR_CLASS}__outline--active`
            }`}
          onClick={() => {
            displayModel && toggleDisplayOutline();
          }}
        /> */}
        <span
          className={`${MODEL_PROPERTIES_SELECTOR_CLASS}__outline ${displayModel &&
            `${MODEL_PROPERTIES_SELECTOR_CLASS}__outline--inactive`
            }`}
          onClick={() => {
            displayOutline && toggleDisplayModel();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { activeModel } = state;
  return { activeModel };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveModel: (payload) => dispatch(setActiveModel(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelPropertiesSelector);
