import React, { Fragment, FC } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import axios from "axios";
import config from "../../modules/config";
import { connect } from "react-redux";
import { getUser } from "../../state/actions";
import { TextField, Typography } from "@mui/material/";
import { UserProfileData } from "../../types/UserProfileData";
type Props = {
  user: UserProfileData;
  getUser: () => void;
  setToggleState: (name: string, toggle: boolean) => void;
  toggle: boolean;
  name: string;
};


const ProfileBirthDateForm = ({
  user,
  getUser,
  setToggleState,
  toggle,
  name
}: any) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const handleDateChange = (date: React.SetStateAction<Date>) => {
    setSelectedDate(date);
  };
  const onSubmitHandler = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    let body = {
      birthDate: selectedDate
    };
    let athorizedHeader = config.AuthorizationHeader();
    axios
      .post(`${process.env.REACT_APP_CROQEE_SERVER_URL}/api/updateuser/` + user._id, body, athorizedHeader)
      .then(res => {
        getUser();
        setToggleState(name, false);
      })
      .catch(err => console.error(err));
  };

  return (
    <Fragment>
      <div className="profile__userInfo">
        <span>Date of Birth</span>
        <div className="profile__userInfo__container">
          {toggle ? (
            <form
              className="profile__userInfo__container__form"
              onSubmit={onSubmitHandler}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  openTo="year"
                  value={selectedDate}
                  onChange={handleDateChange}
                  maxDate={new Date()}

                  renderInput={(params) => <TextField {...params} 
                  className={"profile__userInfo-date-picker"}
                  />}
                />
              </LocalizationProvider>
              <button
                type="submit"
                className="profile__userInfo__container__form__btn"
              >
                Update
              </button>
            </form>
          ) : (
            <Fragment>
              <Typography variant="body2" style={{ fontSize: "1rem" }}>
                {user.birthDate
                  ? user.birthDate.substring(0, 10)
                  : "Add your birth date"}
              </Typography>

              <a
                onClick={() => {
                  setToggleState(name, true);
                }}
              >
                Edit
              </a>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state: { user: any; }) => {
  const user = state.user;
  return { user };
};
const mapDispatchToProps = (dispatch: (arg0: (dispatch: any) => Promise<void>) => any) => {
  return {
    getUser: () => dispatch(getUser())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps as any
)(ProfileBirthDateForm) as any;
