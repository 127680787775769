import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
      underline: {
        "&:hover:not($disabled):not($error):not($focused):before": {
          borderBottom: "1px solid rgba(255, 255, 255, 0.42)"
        },
        "&:hover:not($disabled):not($error):before": {
          borderBottom: "none"
        },
        "&:after": {
          borderBottom: "1px solid #06b3cf"
        },
        "&:before": {
          borderBottom: "1px solid rgba(255, 255, 255, 0.82)"
        },
        color: "#b8b8b8",
        borderBottom: "0.2px solid rgba(255, 255, 255, 0.42)",
        width: "265px"
      }
    }
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          backgroundColor: "#4a4b4b"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "1rem 0",
          color: "#5d5e5e"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          margin: "1rem 0",
          "&$focused": {
            color: "#06b3cf",
            fontWeight: "bold"
          }
        }
      }
    },   
    MuiButton: {
      styleOverrides: {
        root: {
          width: "260px"
        },
        outlined: {
          color: "#06b3cf",
          margin: "1.5rem 0"
        },
        textPrimary: {
          color: "#fff",
          fontWeight: "600",
          "&:hover": {
            backgroundColor: "#4a4b4b",
            borderRadius: "0"
          }
        },
        iconSizeMedium: {
          color: "#DB4437"
        }
      }
    },    
    MuiCard: {
      styleOverrides: {
        root: {
          paddingBottom: "2rem",
          margin: "50px auto 0"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          minWidth: "275px",
          maxWidth: "400px",
          margin: "4rem auto 0.25rem auto",
          backgroundColor: "transparent"
        },
        elevation24: {
          boxShadow: "none"
        },
        rounded: {
          borderRadius: "0"
        }
      }
    }    
  },
  palette: {
    mode: "dark",
    text: {
      primary: "#fd5f00"
    }
  }
});
