import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import rootReducer, { RootState } from '../reducers';

const epicMiddleware = createEpicMiddleware<any, any, RootState>();


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(epicMiddleware)
});

export default store;