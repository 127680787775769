import React from "react";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';
import store from "./store";

const ReduxProvider = ({ children }) => {
    return (
        <Provider store={store}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
                {children}
            </GoogleOAuthProvider>
        </Provider>
    );
};

export default ReduxProvider;