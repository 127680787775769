import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout/layout";
import { UserPendingLoader } from "../../components/userpendingloader/UserPendingLoader";
import { getScoredModels, getUsersScore } from "../../state/actions";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import { RootState } from "../../state/reducers";
import { User } from "../../types/user";
import { UsersScore } from "../../types/UsersScore";
import "./leaderboard.style.scss";
import { Avatar } from "@mui/material";

interface Props {
  getUsersScore: (num?: number) => void;
  getScoredModels: () => void;
  usersScore: UsersScore;
  user?: User;
}

const Leaderboard = (props: Props) => {
  const [overAllPercentage, setOverAllPercentage] = useState(0);
  const { user, usersScore, getUsersScore, getScoredModels } = props;

  useEffect(() => {
    getUsersScore(1);
    getScoredModels();
  }, [getUsersScore, getScoredModels]);

  useEffect(() => {
    const overAllPercentage = Math.round(
      ((usersScore.totalScores - (usersScore.userRank - 1)) /
        usersScore.totalScores) *
        100
    );
    setOverAllPercentage(overAllPercentage);
  }, [usersScore]);

  return (
    <Layout>
      <div className="leaderboard">
        <br />
        <h2 className="leaderboard__title">Community Leaderboards</h2>
        {usersScore && usersScore.data && overAllPercentage ? (
          <>
            {usersScore.userRank > 0 && (
              <div className="leaderboard__overall">
                <div className="leaderboard__overall-percentage">
                  <div className="leaderboard__overall-percentage-circle">
                    <span className="leaderboard__overall-percentage-circle-text">
                      {overAllPercentage}%
                    </span>
                  </div>
                  <div className="leaderboard__overall-percentage-desc">
                    You have more overall scores than{" "}
                    <b>{overAllPercentage}%</b> of other participants
                  </div>
                </div>
              </div>
            )}
            <div className="leaderboard__scores">
              {usersScore &&
                usersScore.data &&
                usersScore.data.map((score: any, i: number) => {
                  return (
                    <React.Fragment key={score._id}>
                      <div
                        className={`leaderboard__scores-row   ${
                          user.email === score.user.email &&
                          "leaderboard__scores-row--self"
                        } `}
                        onClick={() => {
                          window.location.href = `/userprofile/${score._id}`;
                        }}
                      >
                        <span className="leaderboard__scores-row-rank">
                          {" "}
                          {score.rank}
                        </span>
                        <span className="leaderboard__scores-row-img">
                          {score.user.img ? (
                            <Avatar
                              src={
                                `${process.env.REACT_APP_CROQEE_SERVER_URL}/user-image/` +
                                score.user.img.image_data
                              }
                              alt="profile image"
                            />
                          ) : (
                            <Avatar>
                              <PersonSharpIcon />
                            </Avatar>
                          )}
                        </span>
                        <span className="leaderboard__scores-row-user">
                          {score.user && score.user.name}
                        </span>
                        <span className="leaderboard__scores-row-score">
                          {score.total}
                        </span>
                      </div>
                      {score.rank === 20 && usersScore.data.length > 20 && (
                        <div className="leaderboard__scores-etc">. . .</div>
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
            {usersScore && usersScore.userRank > 0 ? (
              <h2 className="leaderboard__rank-desc">
                You're ranked <b>{usersScore.userRank}</b> out of
                <b> {usersScore.totalScores}</b> artists for scores earned!
              </h2>
            ) : (
              <h2 className="leaderboard__rank-desc">
                Currently, you have no scores recorded on the leaderboard. Your
                achievements will be displayed here once you begin attaining
                scores.
              </h2>
            )}
          </>
        ) : (
          <UserPendingLoader caption={"Loading leaderboard data"} />
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => {
  const { usersScore, user, isAuthenticated } = state;
  return { usersScore, user, isAuthenticated };
};
const mapDispatchToProps = (dispatch: any) => ({
  getUsersScore: () => dispatch(getUsersScore()),
  getScoredModels: () => dispatch(getScoredModels()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard as any);
