import {
  GET_USER_ASYNC,
  SET_USER,
  AUTHENTICATE,
  SET_TIMER,
  SHOW_SCORE,
  HIDE_SCORE,
  REMOVE_SCORE,
  SET_IMAGE_PROCESSING,
  SET_HAND_SIDE,
  SET_TIMER_DONE,
  SET_START_IMAGE_PROCESSING,
  SET_ACTIVE_MODEL,
  SET_ACTIVE_MODEL_DRAWN,
  GET_USERS_SCORE_ASYNC,
  GET_SCORED_MODELS_ASYNC,
  SET_CANVAS_DIMENSION,
  SET_INNER_MODEL_DIMENSION,
  SET_IS_DRAWING_STARTED,
  SET_BRUSH_SIZE,
  SET_DRAWING_EVALUATION_ERROR,
  GET_ALL_MODELS_ASYNC
} from "../actions/action-types";
import { UsersScore } from "../../types/UsersScore";
import { DRAWING_EVALUATION_ERROR } from "../../constants/site-content";

export type RootState = {
  user: {};
  isAuthenticated: boolean;
  timer: number;
  showTimer: boolean;
  timerDone: boolean;
  startImageProcessing: boolean;
  scoreClass: string;
  currentScore: string;
  imageProcessing: boolean;
  leftHand: boolean;
  activeModel: {
    model: string; // TODO:create literal types
    isDrawn: boolean;
    modelDataURL: string;
  };
  usersScore: UsersScore[];
  scoredModels: number[];
  canvasWidth: number;
  canvasHeight: number;
  innerModelWidth: number;
  innerModelHeight: number;
  allModels: any;
  //TODO: noticed events were passed to rootState why?
  events?: any;
  isDrawingStarted: boolean;
  brushSize: number;
  drawingEvaluationError: string;
};

export const initialState: RootState = {
  user: {},
  isAuthenticated: false,
  timer: 30,
  showTimer: false,
  timerDone: true,
  startImageProcessing: false,
  scoreClass: "",
  currentScore: "",
  imageProcessing: false,
  leftHand: false,
  activeModel: {
    model: "anatomy",
    isDrawn: false,
    modelDataURL: ''
  },
  usersScore: [],
  scoredModels: [],
  canvasWidth: 0,
  canvasHeight: 0,
  innerModelWidth: 0,
  innerModelHeight: 0,
  isDrawingStarted: false,
  brushSize: 1.6,
  drawingEvaluationError: DRAWING_EVALUATION_ERROR.processingError,
  allModels: []
};

//TODO:  create action type for root reducer

function rootReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case GET_USER_ASYNC:
      return {
        ...state,
        user: action.user
      };
    case AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated
      };
    case SET_TIMER:
      return {
        ...state,
        showTimer: action.showTimer,
        timer: action.timer
      };
    case SET_TIMER_DONE:
      return {
        ...state,
        timerDone: action.done
      };
    case SHOW_SCORE:
      return {
        ...state,
        scoreClass: "drawing-result--show",
        currentScore: action.score
      };
    case HIDE_SCORE:
      return {
        ...state,
        scoreClass: "drawing-result--hide"
      };
    case REMOVE_SCORE:
      return {
        ...state,
        scoreClass: ""
      };
    case SET_IMAGE_PROCESSING:
      return {
        ...state,
        imageProcessing: action.imageProcessing
      };
    case SET_HAND_SIDE:
      return {
        ...state,
        leftHand: action.side
      };
    case SET_START_IMAGE_PROCESSING:
      return {
        ...state,
        startImageProcessing: action.startImageProcessing
      };
    case SET_ACTIVE_MODEL:
      return {
        ...state,
        activeModel: action.activeModel
      };
    case SET_ACTIVE_MODEL_DRAWN:
      const model = state.activeModel.model;
      return {
        ...state,
        activeModel: {
          ...state.activeModel,
          model: model,
          isDrawn: true,
        }
      };
    case GET_USERS_SCORE_ASYNC:
      return {
        ...state,
        usersScore: action.usersScore
      };
    case GET_SCORED_MODELS_ASYNC:
      return {
        ...state,
        scoredModels: action.scoredModels
      };
    case SET_CANVAS_DIMENSION:
      return {
        ...state,
        canvasWidth: action.dimension.canvasWidth,
        canvasHeight: action.dimension.canvasHeight
      };
    case SET_INNER_MODEL_DIMENSION:
      return {
        ...state,
        innerModelWidth: action.dimension.innerModelWidth,
        innerModelHeight: action.dimension.innerModelHeight
      };
    case SET_IS_DRAWING_STARTED:
      return {
        ...state,
        isDrawingStarted: action.isDrawingStarted,
      };
    case SET_BRUSH_SIZE:
      return {
        ...state,
        brushSize: action.brushSize,
      };
    case SET_DRAWING_EVALUATION_ERROR:
      return {
        ...state,
        drawingEvaluationError: action.error,
      };
    case GET_ALL_MODELS_ASYNC:
      return {
        ...state,
        allModels: action.allModels,
      };

    default:
      return state;
  }
}
export default rootReducer;
