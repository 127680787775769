import Auth from './Auth';

const config = {
	AuthorizationHeader: () => {
		const AuthorizationHeader = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `bearer ${Auth.getToken()}`
			}
		};
		return AuthorizationHeader;
	},
	UnAthorizedHeader: () => {
		const AuthorizationHeader = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		return AuthorizationHeader;
	}
};

export default config;
