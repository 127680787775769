import React, { Fragment, FC } from "react";
import AccountSideNav from "../accountsidenav/AccountSideNav";
import "./accountlayout.style.scss";

export const AccountLayout = ({ children }: any) => {
  return (
    <div className="account">
      <AccountSideNav />
      {children}
    </div>
  );
};

export default AccountLayout;
