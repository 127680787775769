import React, { useEffect, useState } from "react";
import { calcCanvasAndModelDim } from "../../lib/CalcCanvasAndModelDim";
import HandSide from "../handside/HandSide";
import "./drawingenvironment.style.scss";

interface IDrawingEnvironmentProps {
  side: string;
}

export const DrawingEnvironment = (props: IDrawingEnvironmentProps | any) => {
  const [elem, setElem] = useState(null);
  useEffect(() => {
    document.documentElement.addEventListener("touchmove", function (event) {
      event.preventDefault();
    });
    document.documentElement.addEventListener("touchstart", function (event) {
      event.preventDefault();
    });
    setElem(document.documentElement);
    /* View in fullscreen */
  }, []);
  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  const { isWrapped } = calcCanvasAndModelDim();
  // const handle = useFullScreenHandle();
  return (
    // <FullScreen handle={handle}>
    <>
      <div
        className={`drawing-environment ${props.side} ${
          isWrapped ? "drawing-environment--column" : ""
        }`}
      >
        {props.children}
        {!isWrapped && <HandSide />}
      </div>
      <button
        style={{ height: "30px", display: "none" }}
        onClick={() => openFullscreen()}
      ></button>
    </>
  );
};
