import React from "react";
import { connect } from "react-redux";
import "./modelimage.style.scss";

const MODEL_IMAGE_CLASS = "model-image";
type Props = {
  innerModelWidth: string;
  innerModelHeight: string;
  imgPath: string;
  description: string;
  leftHand: boolean;
};

const ModelImage = (props: Props) => {
  const { innerModelWidth, innerModelHeight, imgPath, description, leftHand } =
    props;
  const side = leftHand ? "model_left_hand" : "";
  return (
    <img
      alt={description}
      src={imgPath}
      style={{
        width: `${innerModelWidth}px`,
        height: `${innerModelHeight}px`,
      }}
      className={MODEL_IMAGE_CLASS + " " + side}
    />
  );
};
const mapStateToProps = (state) => {
  const { innerModelWidth, innerModelHeight, leftHand } = state;
  return {
    innerModelWidth,
    innerModelHeight,
    leftHand,
  };
};

export default connect(mapStateToProps, {})(ModelImage);
