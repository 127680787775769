import React from 'react';
import { connect } from 'react-redux';
import ModelPropertiesSelector from '../ModelPropertiesSelector/ModelPropertiesSelector';
import rotate_icon from "../../../images/rotate-icon.png";
import UserScoreOverview from '../UsersScores/UsersScores';
import { useState, useEffect } from "react";
import { User } from '../../../types/user';
import "./interactivedrawingmodel.style.scss";
import { CroqeeInteractiveModel } from '@croqee/interactive-model';

const INTERACTIVE_DRAWING_MODEL_CLASS = 'interactive-drawing-model';
export type ThreeDimPosition = {
  x: number,
  y: number,
  z: number
}
export type OutLineSpec = {
  normalMultiplier?: number;
  outlineSharpness?: number;
  normalBias?: number;
  depthMultiplier?: number;
}
type Props = {
  componentRef: any;
  modelUrl: string;
  outLineColor?: number;
  cameraPosition?: ThreeDimPosition;
  allowUserCameraControl?: boolean;
  compete?: boolean;
  showUserScores?: boolean;
  user?: User;
  playingUsers?: any[]
  modelScale?: number;
  controlTarget?: ThreeDimPosition;
  normalMultiplier?: number;
  outLineSpec?: OutLineSpec;
}
type MappedProps = {
  canvasHeight: number;
  canvasWidth: number;
  allowUserCameraControl: boolean;
  isDrawingStarted: boolean;
}

const InteractiveDrawingModel = (props: Props & MappedProps): JSX.Element => {
  const [displayOutline, setDisplayOutline] = useState(true);
  const [displayModel, setDisplayModel] = useState(true);
  const [displayRotateHintOverlay, setDisplayRotateHintOverlay] = useState(false);
  const [allowUserCameraControl, setAllowUserCameraControl] = useState(props.allowUserCameraControl);

  useEffect(() => {
    if (props.allowUserCameraControl) {
      setDisplayRotateHintOverlay(true);
    }
    return () => {
    }
  }, []);

  useEffect(() => {
    if (displayRotateHintOverlay && props.isDrawingStarted) {
      hideRotateHintOverlay();
    }
  }, [displayRotateHintOverlay, props.isDrawingStarted])

  useEffect(() => {
    if (!props.compete) {
      setAllowUserCameraControl(!props.isDrawingStarted)
    }
  }, [props.isDrawingStarted, props.compete])

  const toggleDisplayOutline = () => {
    setDisplayOutline(!displayOutline);
  }
  const toggleDisplayModel = () => {
    setDisplayModel(!displayModel);
  }
  const hideRotateHintOverlay = () => {
    setDisplayRotateHintOverlay(false)
  }
  const {
    canvasHeight,
    canvasWidth,
    componentRef,
    modelUrl,
    outLineColor,
    cameraPosition,
    compete,
    showUserScores,
    user,
    playingUsers,
    modelScale,
    controlTarget,
    outLineSpec
  } = props;
  return (
    <div className={allowUserCameraControl ?
      `${INTERACTIVE_DRAWING_MODEL_CLASS} ${INTERACTIVE_DRAWING_MODEL_CLASS}--enable-control`
      : INTERACTIVE_DRAWING_MODEL_CLASS}
      style={{
        width: `${canvasWidth}px`,
        height: `${canvasHeight}px`,
      }}
      onClick={() => hideRotateHintOverlay()}
      onTouchStart={() => hideRotateHintOverlay()}
    >

      {compete && showUserScores && (
        <UserScoreOverview
          width={canvasWidth}
          height={canvasHeight}
          playingUsers={playingUsers}
          user={user}
        />
      )}

      {displayRotateHintOverlay &&
        <div
          style={{
            width: `${canvasWidth}px`,
            height: `${canvasHeight}px`,
            margin: `0 0 -${canvasHeight}px 0`,
          }}
          className={`${INTERACTIVE_DRAWING_MODEL_CLASS}__rotate-hint-overlay`}>
          <img src={rotate_icon} />
        </div>
      }
      <ModelPropertiesSelector
        height={canvasHeight}
        displayOutline={displayOutline}
        displayModel={displayModel}
        toggleDisplayOutline={toggleDisplayOutline}
        toggleDisplayModel={toggleDisplayModel}
      />
      {canvasWidth && canvasHeight && modelUrl && componentRef &&
        //@ts-nocheck
        <CroqeeInteractiveModel
          ref={componentRef}
          //@ts-ignore
          modelUrl={modelUrl}
          outLineColor={outLineColor || 0x73A5C6}
          cameraPosition={cameraPosition || { x: 0, y: 20, z: 75 }}
          displayOriginalModel={displayModel}
          displayModelOutline={displayOutline}
          modelWidth={canvasWidth}
          modelHeight={canvasHeight}
          allowUserCameraControl={allowUserCameraControl}
          modelScale={modelScale}
          controlTarget={controlTarget}
          normalMultiplier={outLineSpec?.normalMultiplier}
          outlineSharpness={outLineSpec?.outlineSharpness}
          normalBias={outLineSpec?.normalBias}
          depthMultiplier={outLineSpec?.depthMultiplier}
        />
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    canvasHeight,
    canvasWidth,
    isDrawingStarted,
    user
  } = state;
  return {
    canvasHeight,
    canvasWidth,
    isDrawingStarted,
    user
  };
};

export default connect(mapStateToProps, {})(InteractiveDrawingModel);
