import axios from "axios";
import config from "../../modules/config";
import {
  SET_USER,
  GET_USER_ASYNC,
  AUTHENTICATE,
  SET_TIMER,
  SHOW_SCORE,
  HIDE_SCORE,
  REMOVE_SCORE,
  SET_IMAGE_PROCESSING,
  SET_HAND_SIDE,
  SET_TIMER_DONE,
  SET_START_IMAGE_PROCESSING,
  SET_ACTIVE_MODEL,
  SET_ACTIVE_MODEL_DRAWN,
  GET_USERS_SCORE_ASYNC,
  GET_SCORED_MODELS_ASYNC,
  SET_CANVAS_DIMENSION,
  SET_INNER_MODEL_DIMENSION,
  SET_IS_DRAWING_STARTED,
  SET_BRUSH_SIZE,
  SET_DRAWING_EVALUATION_ERROR,
  GET_ALL_MODELS_ASYNC
} from "./action-types";
import Auth from "../../modules/Auth";

//Get User
function getUserAsync(payload: any) {
  return { type: GET_USER_ASYNC, user: payload.user };
}
export function getUser() {
  return async (dispatch: any) => {
    let AuthorizationHeader = config.AuthorizationHeader();

    //Set the authorization header as axios default header and delete that on log out
    try {
      const response = await axios.get(`${process.env.REACT_APP_CROQEE_SERVER_URL}/api/getuser`, AuthorizationHeader);
      if (response) {
        let user = response.data;
        dispatch(authenticate(true));
        dispatch(getUserAsync(user));
      }
    } catch (e) {
      console.error(e);
    }
  };
}

//Set User
export function setUser(payload: any) {
  return { type: SET_USER, user: payload };
}
export function authenticate(payload: any) {
  return { type: AUTHENTICATE, isAuthenticated: payload };
}

//Timer
export function setTimer(payload: any) {
  return {
    type: SET_TIMER,
    showTimer: payload.showTimer,
    timer: payload.timer
  };
}
export function setTimerDone(payload: any) {
  return { type: SET_TIMER_DONE, done: payload };
}

//Invoke Score
export function invokeScore(payload: any) {
  return (dispatch: any) => {
    dispatch(showScore(payload));
    setTimeout(() => {
      dispatch(hideScore());
      setTimeout(() => {
        dispatch(setTimerDone(true));
        dispatch(setStartImageProcessing(false));
        dispatch(setActiveModelDrawn());
      }, 1000);
    }, 4500);
  };
}
export function showScore(payload: any) {
  return { type: SHOW_SCORE, score: payload };
}
export function hideScoreAsync() {
  return { type: HIDE_SCORE };
}
export function removeScoreAsync() {
  return { type: REMOVE_SCORE };
}
export function hideScore() {
  return (dispatch: any) => {
    dispatch(hideScoreAsync());
    setTimeout(() => {
      dispatch(removeScoreAsync());
    }, 500);
  };
}

export function setImageProcessing(payload: any) {
  return { type: SET_IMAGE_PROCESSING, imageProcessing: payload };
}

export function setHandSide(payload: any) {
  return { type: SET_HAND_SIDE, side: payload };
}
export function setStartImageProcessing(payload: any) {
  return { type: SET_START_IMAGE_PROCESSING, startImageProcessing: payload };
}
export function setActiveModel(payload: any) {
  return { type: SET_ACTIVE_MODEL, activeModel: payload };
}
export function setActiveModelDrawn() {
  return { type: SET_ACTIVE_MODEL_DRAWN };
}

//Get UsersScore
function getUsersScoreAsync(payload: any) {
  return { type: GET_USERS_SCORE_ASYNC, usersScore: payload };
}
export function getUsersScore() {
  return async (dispatch: any) => {
    try {
      const response = await axios.get( `${process.env.REACT_APP_CROQEE_SERVER_URL}/score/userscore`, {
        headers: {
          Authorization: "Bearer " + Auth.getToken()
        }
      });
      if (response) {
        const usersScore = response.data.usersScore;
        dispatch(getUsersScoreAsync(usersScore));
      }
    } catch (e) {
      console.error(e);
      dispatch(getUsersScoreAsync({}));
    }
  };
}

//Get ScoredModels
function getScoredModelsAsync(payload: any) {
  return { type: GET_SCORED_MODELS_ASYNC, allModels: payload };
}

export function getScoredModels() {
  return async (dispatch: any) => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_CROQEE_SERVER_URL}/score/scoredmodels`, {
          headers: {
            Authorization: "Bearer " + Auth.getToken()
          }
        });
      if (response) {
        const scoredModels = response.data.scoredModels;
        dispatch(getScoredModelsAsync(scoredModels));
      }
    } catch (e) {
      console.error(e);
      dispatch(getScoredModelsAsync({}));

    }
  };
}

//Get Models
function getAllModelsAsync(payload: any) {
  return { type: GET_ALL_MODELS_ASYNC, allModels: payload };
}
export function getAllModels() {
  return async (dispatch: any) => {
    const response = await axios.get(`${process.env.REACT_APP_CROQEE_SERVER_URL}/models/getAllModels`, {
      headers: {
        Authorization: "Bearer " + Auth.getToken()
      }
    });

    if (response) {
      const models = response.data.models;
      dispatch(getAllModelsAsync(models));
    };
  };
}

export function setCanvasDimension(payload: any) {
  return { type: SET_CANVAS_DIMENSION, dimension: payload };
}

export function setInnerModelDimension(payload: any) {
  return { type: SET_INNER_MODEL_DIMENSION, dimension: payload };
}

export function setIsDrawingStarted(payload: any) {
  return { type: SET_IS_DRAWING_STARTED, isDrawingStarted: payload }
}

export function setBrushSize(payload: any) {
  return { type: SET_BRUSH_SIZE, brushSize: payload }
}

export function setDrawingEvaluationError(payload: any) {
  return { type: SET_DRAWING_EVALUATION_ERROR, error: payload }
}

