import * as React from "react"
import { Footer } from "../footer/Footer"
import NavBar from "../navbar/NavBar"
import "./layout.style.scss";

const Layout = ({ children }: any) => {

    return (
        <>
            <NavBar />
            <main>{children}</main>
            <Footer />
        </>
    )
}

export default Layout