import React, { useEffect } from "react";
import Auth from "../../modules/Auth";
import { connect } from "react-redux";
import { authenticate, setUser } from "../../state/actions";
import { useNavigate } from "react-router-dom"; 

const LogoutFunction = ({authenticate, setUser}) => {
  const navigate = useNavigate();

  useEffect(() => {
    Auth.deauthenticateUser();
    authenticate(false)
    setUser({})
    navigate("/");
  }, []);

  return <p>Logging out...</p>;
};

const mapDispatchToProps = (dispatch: (arg0: { type: string; isAuthenticated?: any; user?: any; }) => any) => ({
  authenticate: (payload: any) => dispatch(authenticate(payload)),
  setUser: (payload: any) => dispatch(setUser(payload))
});

export default connect(null, mapDispatchToProps)(LogoutFunction);
