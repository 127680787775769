import React, { FC } from "react";
import "./clubthumbnail.style.scss";

type Props = {
  drawingField: string;
  handleNavigationToCompetition: (drawingField: string) => void;
  text: string;
  image: string;
};

export const ClubThumbnail: FC<Props> = ({
  drawingField,
  handleNavigationToCompetition,
  text,
  image
}) => (
  <div
    className={"clubthumbnail clubthumbnail--" + drawingField}
    onClick={() => handleNavigationToCompetition(drawingField)}
  >
    <img className="clubthumbnail__img" alt="Croqee drawing club thumbnail" src ={image}/>
    <div className="clubthumbnail__overlay"></div>
    <span className="clubthumbnail__text">{text}</span>
  </div>
);
