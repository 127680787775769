import React, { useState, useEffect, FC } from "react";
import { TextField, Button, Card } from "@mui/material/";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import config from "../../modules/config";
import { PasswordInputsTypes } from "../../types/PasswordInputsTypes";
import { useLocation } from "react-router";
import { theme } from "../../components/MuiTheme";
import Layout from "../../components/layout/layout";
import "./reset-password.style.scss";

export const ResetPass = (props) => {
  const [successMessage] = useState();
  const [msg, setMsg] = useState<string>();
  const [errors, setErrors] = useState<PasswordInputsTypes>();
  const [email, setEmail] = useState<string>();
  const [userToken, setToken] = useState<string>();
  const [password, setPassword] = useState<Partial<PasswordInputsTypes>>();
  const location = useLocation();

  useEffect(() => {
    const pathname = location?.pathname;
    const resetPasswordToken = pathname?.split("/").pop();
    axios
      .get(
        `${process.env.REACT_APP_CROQEE_SERVER_URL}/auth/reset-token-check`,
        {
          params: {
            resetPasswordToken,
          },
        }
      )
      .then((response) => {
        if (response.data.message === "password reset link a-ok") {
          setMsg(null);
          setEmail(response.data.email);
          setToken(resetPasswordToken);
        }
      })
      .catch((error) => {
        setMsg("This link has expired or is invalid.");
      });
  }, [props.location?.pathname, location]);

  const onChange = (e) => {
    let input = e.target;
    let isValid = input.checkValidity();
    setPassword((prevPass) => ({ ...prevPass, [input.name]: input.value }));
    if (!isValid) {
      setErrors((prevErr) => ({
        ...prevErr,
        [input.name]: input.validationMessage,
      }));
    } else {
      setErrors(null);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const isValid = form.checkValidity();
    const formData = new FormData(form) as any;
    const validationMessages = Array.from(formData.keys()).reduce(
      (acc: any, key: any) => {
        acc[key] = form.elements[key].validationMessage;
        return acc;
      },
      {}
    );
    setErrors(validationMessages);
    if (isValid) {
      //check if passwords match
      if (password.newPassword !== password.repeatNewPassword) {
        setErrors({
          newPassword: "Passwords do not match.",
          repeatNewPassword: "Passwords do not match.",
        });
      } else {
        //check if the length of the pass is more than 8
        if (password.newPassword.length < 8) {
          setErrors({
            newPassword: "Password must be at least 8 caracters.",
            repeatNewPassword: "Password must be at least 8 caracters.",
          });
        } else {
          //update password
          let athorizedHeader = config.AuthorizationHeader();
          let body = {
            email: email,
            resetPasswordToken: userToken,
            password: password.newPassword,
          };
          axios
            .put(
              `${process.env.REACT_APP_CROQEE_SERVER_URL}/auth/reset-pass`,
              body,
              athorizedHeader
            )
            .then((res) => {
              if (res.status === 200) {
                setMsg(res.data.message);
              }
            })
            .catch(() => {
              setMsg("Sorry. Something went wrong!");
            });
        }
      }
    }
  };

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <Card variant="outlined" className="reset-password" square>
          {msg !== null ? (
            <p className={"reset-password__msg"}>{msg}</p>
          ) : (
            <div className="container">
              <form
                action="/"
                onSubmit={onSubmit}
                className="auth_form"
                noValidate
              >
                {!successMessage && (
                  <h3 className="reset-password__heading">
                    {" "}
                    Provide your password
                  </h3>
                )}
                {successMessage && (
                  <p className="reset-password__success-message">
                    {successMessage}
                  </p>
                )}
                <TextField
                  required
                  label="password"
                  id="password"
                  name="newPassword"
                  type="password"
                  onChange={onChange}
                  placeholder="Your password"
                  error={Boolean(errors !== undefined)}
                  helperText={errors && errors.newPassword}
                  className={"reset-password__input"}
                />
                <TextField
                  required
                  label="Repeat Password"
                  id="password"
                  name="repeatNewPassword"
                  type="password"
                  onChange={onChange}
                  placeholder="Your password"
                  error={Boolean(errors !== undefined)}
                  helperText={errors && errors.repeatNewPassword}
                  className={"reset-password__input"}
                />
                <Button
                  variant="outlined"
                  type="submit"
                  className={"reset-password__submit"}
                >
                  Update Password
                </Button>
              </form>
            </div>
          )}
        </Card>
      </ThemeProvider>
    </Layout>
  );
};
