import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Model } from "../../model/DrawingModel/DrawingModel";
import { ScoreIndicator } from "../../scoreindicator/scoreIndicator";
import "./drawingresult.style.scss";

const DRAWING_RESULT_CLASS = "drawing-result";

type Props = {
  canvasWidth: number;
  canvasHeight: number;
  innerModelWidth: number;
  innerModelHeight: number;
  scoreClass: string;
  baseURL: string;
  isInHomePage: boolean;
  activeModel: Model;
  currentScore: number;
  imgPath: string;
  model: Model;
};
type MappedProps = {
  drawingEvaluationError: string;
};

const DrawingResult = ({
  canvasWidth,
  canvasHeight,
  innerModelWidth,
  innerModelHeight,
  scoreClass,
  activeModel,
  model,
  baseURL,
  drawingEvaluationError,
  currentScore,
}: Props & MappedProps) => {
  return (
    <div
      id="userScore"
      className={DRAWING_RESULT_CLASS + " " + scoreClass}
      style={{
        width: `${canvasWidth}px`,
        height: `${canvasHeight}px`,
        marginBottom: `-${canvasHeight}px`,
      }}
    >
      {baseURL ? (
        <React.Fragment>
          <img
            alt="Drawing result"
            className={DRAWING_RESULT_CLASS + "__drawing"}
            draggable="false"
            style={{
              width: `${canvasWidth}px`,
              height: `${canvasHeight}px`,
              marginBottom: `-${canvasHeight}px`,
            }}
            src={baseURL}
          />

          <img
            alt="Original model on drawing result"
            className={DRAWING_RESULT_CLASS + "__model"}
            draggable="false"
            style={{
              width: `${innerModelWidth}px`,
              height: `${innerModelHeight}px`,
            }}
            src={
              activeModel.modelDataURL ? activeModel.modelDataURL : model.url
            }
          />

          <span className={DRAWING_RESULT_CLASS + "__score"}>
            Score
            <span className={DRAWING_RESULT_CLASS + "__score-number"}>
              {" "}
              {currentScore && (
                <ScoreIndicator score={currentScore} />
              )}
            </span>
          </span>
        </React.Fragment>
      ) : (
        <span
          style={{
            position: "absolute",
            width: "400px",
            textAlign: "center",
            top: `${canvasHeight / 2 - 50}px`,
            left: `${canvasWidth / 2 - 200}px`,
            color: "white",
            fontSize: "28px",
          }}
        >
          {drawingEvaluationError}
        </span>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    currentScore,
    scoreClass,
    leftHand,
    activeModel,
    canvasWidth,
    canvasHeight,
    innerModelWidth,
    innerModelHeight,
    drawingEvaluationError,
  } = state;
  return {
    currentScore,
    scoreClass,
    leftHand,
    activeModel,
    canvasWidth,
    canvasHeight,
    innerModelWidth,
    innerModelHeight,
    drawingEvaluationError,
  };
};

export default connect(mapStateToProps, {})(DrawingResult);
