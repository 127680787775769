import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setHandSide } from "../../state/actions";
import "./handside.style.scss";

type HandSides = "hand-side--fade-in" | "hand-side--fade-out";

type Props = {
  timerDone: boolean;
  setHandSide: (bool: boolean) => void;
  leftHand: boolean;
  isDrawingStarted: boolean;
};
const HandSide = ({
  timerDone,
  setHandSide,
  leftHand,
  isDrawingStarted,
}: Props) => {
  const [handSideStatusClass, setHandSideStatusClass] = useState<HandSides>(null);
  useEffect(() => {
    if (timerDone && !isDrawingStarted) {
      setHandSideStatusClass("hand-side--fade-in");
    } else {
      setHandSideStatusClass("hand-side--fade-out");
    }
  }, [timerDone, isDrawingStarted]);

  return (
    <div id="handside" className={"hand-side " + handSideStatusClass}>
      <input
        onClick={() => setHandSide(true)}
        id="toggle-off"
        className="toggle toggle-left"
        name="toggle"
        type="radio"
        defaultChecked={leftHand}
      />
      <label htmlFor="toggle-off" className="btn">
        Left hand
      </label>
      <input
        onClick={() => setHandSide(false)}
        id="toggle-on"
        className="toggle toggle-right"
        name="toggle"
        type="radio"
        defaultChecked={!leftHand}
      />
      <label htmlFor="toggle-on" className="btn">
        Right hand
      </label>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { leftHand, timerDone, isDrawingStarted } = state;
  return { leftHand, timerDone, isDrawingStarted };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setHandSide: (payload) => dispatch(setHandSide(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HandSide);
