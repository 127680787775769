import React, { useState, FC } from "react";
import { ProfileForm } from "./ProfileForm";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../modules/config";
import { getUser } from "../../state/actions";
import ProfileBirthDateForm from "./ProfileBirthDateForm";
import ProfileUsernameForm from "./ProfileUsernameForm";
import ProfileCityForm from "./ProfileCityForm";
import ProfileImgForm from "./ProfileImgForm";
import { Typography } from "@mui/material";
import { UserProfileData } from "../../types/UserProfileData";
import { ProfileError } from "../../types/Profile";
import "./profile.style.scss";

type Props = {
  user: UserProfileData;
  getUser: () => void;
};

const Profile: FC<Props> = ({ user, getUser }) => {
  const [state, setState] = useState<UserProfileData>({
    name: user.name,
    city: user.city,
    behance: user.behance,
    instagram: user.instagram,
    facebook: user.facebook,
    website: user.website,
    birthDate: new Date(),
    img: user.img
  });
  const [profileError, setProfileError] = useState<ProfileError | null>();
  const [toggle, setToggle] = useState({
    name: false,
    city: false,
    website: false,
    facebook: false,
    instagram: false,
    behance: false,
    birthDate: false,
    image: false
  });

  const onchangeHandler = e => {
    const input = e.target;
    const isValid = input.checkValidity();
    const field = input.name;
    const _state = state;
    _state[field] = input.value;
    setState(_state);
    if (!isValid) {
      setProfileError(prevError => ({
        ...prevError,
        [input.name]: input.validationMessage
      }));
    } else {
      setProfileError(prevError => ({
        ...prevError,
        [input.name]: ""
      }));
    }
  };

  const setToggleState = (name, bool) => {
    toggle[name] = bool;
    setToggle({
      ...toggle
    });
  };

  const onSubmitHandler = platform => e => {
    e.preventDefault();
    const form = e.target;
    const isValid = form.checkValidity();
    const formData = new FormData(form) as any;
    const validationMessages = Array.from(formData.keys()).reduce(
      (acc: any, key: any) => {
        acc[key] = form.elements[key].validationMessage;
        return acc;
      },
      {}
    );
    setProfileError(validationMessages as ProfileError);

    if (isValid) {
      let body = {
        [platform]: state[platform]
      };
      let athorizedHeader = config.AuthorizationHeader();
      axios
        .post(`${process.env.REACT_APP_CROQEE_SERVER_URL}/api/updateuser/` + user._id, body, athorizedHeader)
        .then((_res: any) => {
          getUser();
          setToggle(state => {
            return {
              ...state,
              [platform]: false
            };
          });
        })
        .catch(err => console.error(err));
    }
  };
  return (
    <div className="profile">
      <h2>Profile</h2>
      <div className="profile__img-name-wrapper">
        <ProfileImgForm
          name="image"
          setToggleState={setToggleState}
          toggle={toggle.image}
        />
        <div className="profile__img-name-wrapper__name">
          <ProfileUsernameForm
            name={"name"}
            onchange={onchangeHandler}
            onsubmit={onSubmitHandler}
            setToggleState={setToggleState}
            toggle={toggle.name}
            state={state.name}
            userProfileData={user.name}
          />
        </div>
      </div>
      <div className="profile__userInfo">
        <span>Email</span>
        <div className="profile__userInfo__container">
          <Typography variant="body2" style={{ fontSize: "1rem" }}>
            {user.email}
          </Typography>
        </div>
      </div>
      <ProfileCityForm
        name={"city"}
        setToggleState={setToggleState}
        toggle={toggle.city}
      />
      <ProfileBirthDateForm
        name="birthDate"
        setToggleState={setToggleState}
        toggle={toggle.birthDate}
      />
      <div className="profile__links">
        <ProfileForm
          name={"website"}
          onchange={onchangeHandler}
          onsubmit={onSubmitHandler}
          setToggleState={setToggleState}
          toggle={toggle.website}
          state={state.website}
          userProfileData={user.website}
          profileError={profileError}
        />
        <ProfileForm
          name={"facebook"}
          onchange={onchangeHandler}
          onsubmit={onSubmitHandler}
          setToggleState={setToggleState}
          toggle={toggle.facebook}
          state={state.facebook}
          userProfileData={user.facebook}
          profileError={profileError}
        />
        <ProfileForm
          name={"behance"}
          onchange={onchangeHandler}
          onsubmit={onSubmitHandler}
          setToggleState={setToggleState}
          toggle={toggle.behance}
          state={state.behance}
          userProfileData={user.behance}
          profileError={profileError}
        />
        <ProfileForm
          name={"instagram"}
          onchange={onchangeHandler}
          onsubmit={onSubmitHandler}
          setToggleState={setToggleState}
          toggle={toggle.instagram}
          userProfileData={user.instagram}
          profileError={profileError}
          state={state.instagram}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return { user };
};
const mapDispatchToProps = dispatch => {
  return {
    getUser: () => dispatch(getUser())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
