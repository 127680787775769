import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/croqee-outline-white.png";
import { connect } from "react-redux";
import { getScoredModels, getUser, getUsersScore } from "../../state/actions";
import { ActionBtnNav } from "./ActionBtnNav";
import default_image from "../../images/user-profile.svg";
import DropDown from "./DropDown";
import { Avatar } from "@mui/material";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import { NavbarContact } from "./NavbarContact";
import "./navbar.style.scss";
import { User } from "../../types/user";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Auth from "../../modules/Auth";

export const APP_URL = "https://app.croqee.com";

interface Props {
  getUser: () => void;
  isAuthenticated: boolean;
  user: User;
  getUsersScore: (num?: number) => void;
  getScoredModels: () => void;
}

const NavBar = ({
  isAuthenticated,
  getUser,
  getUsersScore,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (Auth.isUserAuthenticated()) {
      if (isAuthenticated) {
        getUsersScore();
      } else {
        getUser();
      }
    }
  }, [isAuthenticated, getUser, getUsersScore]);
  const checkBoxHandler = (e) => {
    setIsChecked(!isChecked);
  };
  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  let styles = {
    orange: {
      color: "#06b3cf",
      fontWeight: 600,
    },
    lightBlue: {
      color: "#06b3cf",
      fontWeight: 600,
    },
  };

  return (
    <div className="nav">
      <input
        type="checkbox"
        id="nav-check"
        checked={isChecked}
        value={isChecked + ""}
        onChange={checkBoxHandler}
      />
      <Link to="/">
        <img
          src={logo}
          id="logo"
          alt="Croqee logo"
          className="nav-header-title"
        />
      </Link>
      {}
      <button type="button" className="hamburger">
        <label htmlFor="nav-check">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </label>
      </button>
      {isAuthenticated ? (
        <div className="nav-links">
          {/* <Link
            className="nav-link"
            to="/"
            style={currentPath === "/" ? styles.lightBlue : {}}
          >
            Home
          </Link>
          <Link
            className="nav-link"
            to="/leaderboard"
            style={currentPath === "/leaderboard" ? styles.lightBlue : {}}
          >
            Leaderboard
          </Link> */}
          {/* <span className="nav-link">
            <div className="profile-nav">
              <div className="user-img">
                {props.user?.img ? (
                  <Avatar
                    src={
                      `${process.env.REACT_APP_CROQEE_SERVER_URL}/user-image/` +
                      props.user?.img.image_data
                    }
                    alt="profile image"
                  />
                ) : (
                  <Avatar src={default_image} alt="profile image" />
                )}
              </div>
              <div className="user-dropdown-wrapper">
                <div className="user-name" onClick={handleDropdown}>
                  {props.user?.name}
                  {""}
                  <span className={dropdown ? "arrow up" : "arrow down"} />
                </div>
                <div className={`profile-list ${dropdown ? "open" : "close"}`}>
                  <DropDown />
                </div>
              </div>
            </div>
          </span> */}

          <ActionBtnNav
            onclick={() => {
              window.location.href = APP_URL;
            }}
            endIcon={
              <i
                style={{ marginLeft: "0.5rem" }}
                className="fas fa-arrow-right"
              ></i>
            }
            btnText={"Start drawing"}
          />
          <NavbarContact />
        </div>
      ) : (
        <div className="nav-links">
          {/* <Link
            className="nav-link"
            to="/"
            style={currentPath === "/" ? styles.lightBlue : {}}
          >
            Home
          </Link>

          <Link
            className="nav-link"
            to="/signup"
            style={currentPath === "/signup" ? styles.lightBlue : {}}
          >
            Sign up
          </Link>
          <Link
            className="nav-link"
            to="/login"
            style={currentPath === "/login" ? styles.lightBlue : {}}
          >
            Login
          </Link> */}
          <ActionBtnNav
            onclick={() => {
              window.location.href = APP_URL;
            }}
            endIcon={
              <i
                style={{ marginLeft: "0.5rem" }}
                className="fas fa-arrow-right"
              ></i>
            }
            btnText={"Start drawing"}
          />
          <NavbarContact />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isAuthenticated, user } = state;
  return { isAuthenticated, user };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUser()),
    getUsersScore: () => dispatch(getUsersScore()),
    getScoredModels: () => dispatch(getScoredModels()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
