import React from "react";
import Layout from "../../components/layout/layout";
import AccountLayout from "../../components/accountlayout/AccountLayout";
import Profile from "../../components/profile/profile";
import Password from "../../components/password/Password";
import Privacy from "../../components/privacy/Privacy";
import {  Routes, Route } from "react-router-dom";
type Props = {
  path?: any;
};
// eslint-disable-next-line no-empty-pattern
const Account = ({}: Props) => {
  return (
    <Layout>
      <AccountLayout>
          <Routes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/password" element={<Password />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
      </AccountLayout>
    </Layout>
  );
};

export default Account;
